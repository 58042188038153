import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const slice = createSlice({
  name: 'toEditLeadGoal',
  initialState,
  reducers: {
    setAllLeadGoals(state, action) {
      state = action.payload;
      return state;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setAllLeadGoals } = slice.actions;

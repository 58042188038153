/* eslint-disable */
import {
  Box,
  Checkbox,
  Container,
  Divider,
  Grid,
  ListItem,
  Paper,
  Stack,
  Switch,
  Typography,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { v4 } from 'uuid';
import { useEffect, useState } from 'react';
import { url } from '../../../config';
import useAuth from '../../../hooks/useAuth';
import axios from 'axios';
import { useSelector } from 'react-redux';

export default function DashboardPermissions({ accountType }) {
  let accType;
  if (accountType === 'superAdmin') accType = 'SUPER_ADMIN';
  else if (accountType === 'admin') accType = 'ADMIN';
  else if (accountType === 'agent') accType = 'AGENT';
  else if (accountType === 'public') accType = 'PUBLIC';

  const permissionss = useSelector((state) => state.allPermissions.permissions);
  const state = useSelector((state) => state);

  const { user } = useAuth();

  const permission = useSelector((state) => state.allPermissions.permissions);
  const userPermissions = permission.find((data) => data.acctype === user.account_type);

  const canUpdate = userPermissions?.action[5].permissions.includes('update');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const permissionDetails = [...formData];
    permissionDetails.map((permiss) => {
      permiss.permissions = [];
    });
    setFormData(permissionDetails);
  }, [accountType]);

  const fetchPermissions = async () => {
    let filtered = permissionss.filter((res) => res.acctype === accType);
    filtered = filtered[0].action;
    let permissionsData = [...formData];
    permissionsData.map((permiss) => {
      let filterOne = filtered.filter((per) => per.screen === permiss.page);
      permiss.permissions = filterOne[0]?.permissions;
    });
    setFormData(permissionsData);
  };

  useEffect(() => {
    if (permissionss.length > 0) {
      fetchPermissions();
    }
  }, [accountType, permissionss]);

  const Permissions = {
    usermanagement: {
      create: true,
      update: true,
      modify: true,
      view: true,
      whitelabelling: true,
    },
  };

  const saveDetails = async () => {
    const myHeaders = new Headers();
    myHeaders.append('authorization', `Bearer ${JSON.parse(window.localStorage.getItem('user')).token}`);
    myHeaders.append('Content-Type', 'application/json');

    const permissionDetails = [...formData];
    let finalDetails = [];
    const foundAccount = permissionss?.find((res) => res.acctype === accType);
    permissionDetails.map((permiss) => {
      finalDetails.push({
        screen: permiss.page,
        permissions: permiss.permissions,
      });
    });

    finalDetails.map((finalData) => {
      const temp = foundAccount?.action?.find((data) => data.screen === finalData.screen);
      finalData.id = temp.id;
    });

    const raw = JSON.stringify([
      {
        createdBy: '1',
        updatedBy: '1',
        createdAt: null,
        updatedAt: null,
        acctype: accType,
        action: finalDetails,
      },
    ]);

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };
    let apiUrl = ``;
    apiUrl = url.concat(`/user/${user.id}/savePermissions`);

    fetch(apiUrl, requestOptions)
      .then((response) => response.text())
      .then((result) => {})
      .catch((error) => console.log('error', error));

    // const res = await fetch(apiUrl, requestOptions);
  };

  const [formData, setFormData] = useState([
    {
      id: 1,
      title: 'User Management',
      page: 'User-Management',
      permissions: [],
      checkboxData: [
        {
          name: 'create',
          value: 'Create',
        },
        {
          name: 'delete',
          value: 'Delete',
        },
        {
          name: 'modify',
          value: 'Modify',
        },
        {
          name: 'view',
          value: 'View',
        },
      ],
    },
    {
      id: 2,
      title: 'Account Management',
      page: 'Account-Management',
      permissions: [],
      checkboxData: [
        {
          name: 'create',
          value: 'Create',
        },
        {
          name: 'delete',
          value: 'Delete',
        },
        {
          name: 'modify',
          value: 'Modify',
        },
        {
          name: 'view',
          value: 'View',
        },
      ],
    },
    {
      id: 3,
      title: 'Account Settings',
      page: 'Account-Settings',
      permissions: [],
      checkboxData: [
        {
          name: 'white-labelling',
          value: 'White-labelling',
        },
      ],
    },
    {
      id: 4,
      title: 'Lead Management',
      page: 'Lead-Management',
      permissions: [],
      checkboxData: [
        {
          name: 'create',
          value: 'Create',
        },
        {
          name: 'delete',
          value: 'Delete',
        },
        {
          name: 'modify',
          value: 'Modify',
        },
        {
          name: 'view',
          value: 'View',
        },
      ],
    },
    {
      id: 6,
      title: 'Analytics',
      page: 'Analytics',
      permissions: [],
      checkboxData: [
        {
          name: 'view',
          value: 'View',
        },
      ],
    },
    {
      id: 7,
      title: 'Dashboard Settings',
      page: 'Dashboard-Setting',
      permissions: [],
      checkboxData: [
        {
          name: 'view',
          value: 'View',
        },
        {
          name: 'update',
          value: 'Update',
        },
      ],
    },
    {
      id: 8,
      title: 'Profile',
      page: 'Profile',
      permissions: [],
      checkboxData: [
        {
          name: 'update',
          value: 'Update',
        },
      ],
    },
  ]);

  const handlepermission = (screen, operation, value) => {
    if (!canUpdate) {
      return;
    }
    let permissionDetailss = [...formData];
    let permissions = [];
    let newPermission = permissionDetailss.map((permiss) => {
      permissions = [...permiss.permissions];
      if (permiss.page === String(screen)) {
        if (operation === 'add') {
          permissions = [...permissions, value.toLowerCase()];
        } else {
          let index = permiss.permissions.indexOf(value.toLowerCase());
          if (index > -1) {
            permissions.splice(index, 1);
          }
        }
      }
      return { ...permiss, permissions };
    });
    setFormData(newPermission);
  };

  return (
    <>
      {permissionss.length > 0 ? (
        <Container width="100%">
          {formData.map((item, index) => (
            <Box key={v4()}>
              <Typography variant="h5">{item.title}</Typography>

              <Grid spacing={2} container style={{ margin: '5%' }} width="100%">
                {item.checkboxData.map((per) => (
                  <Grid key={v4()}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item?.permissions?.includes(per.name) ? true : false}
                          onChange={(e) => {
                            if (e.target.checked) handlepermission(item.page, 'add', per.value);
                            else handlepermission(item.page, 'remove', per.value);
                          }}
                        />
                      }
                      label={per.value}
                    />
                  </Grid>
                ))}
              </Grid>
              <Divider />
            </Box>
          ))}
          <Stack alignItems="flex-start" sx={{ mt: 3 }}>
            <LoadingButton type="submit" onClick={saveDetails} variant="contained">
              Save Changes
            </LoadingButton>
          </Stack>
        </Container>
      ) : (
        <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />
      )}
    </>
  );
}

// import { createSlice } from '@reduxjs/toolkit';

// const initialState = [];

// const slice = createSlice({
//   name: 'toAddAnalytics',
//   initialState,
//   reducers: {
//     setAllAnalytics(state, action) {
//       state = action.payload;
//       return state;
//     },
//   },
// });

// export default slice.reducer;

// export const { setAllAnalytics } = slice.actions;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { url } from '../../config';

// const initialState = [];

const name = 'analytics';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

function createInitialState() {
  return {
    analytics: {},
  };
}

function createExtraActions() {
  return {
    fetchAnalytics: fetchAnalytics(),
    // deleteAnalytics: deleteAnalytics(),
    // updateAnalytics: updateAnalytics(),
    // createAnalytics: createAnalytics(),
  };
}

function fetchAnalytics() {
  return createAsyncThunk(`${name}/fetchAnalytics`, async () =>
    axios.get(url.concat(`/analytics/agent`), {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
    })
  );
}

// function deleteAnalytics() {
//   return createAsyncThunk(`${name}/deleteAnalytics`, async (userIds) => {
//     try {
//       const response = await axios.delete(
//         url.concat(`/user/${JSON.parse(localStorage.getItem('user')).id}/lead/${userIds}`),
//         {
//           headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
//         }
//       );
//       return { userIds, response };
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       throw err.response.data;
//     }
//   });
// }

// function updateAnalytics() {
//   return createAsyncThunk(`${name}/updateAnalytics`, async (obj) => {
//     try {
//       const response = await axios.post(
//         url.concat(`/admin/${JSON.parse(localStorage.getItem('user')).id}/profile/update`),
//         obj,
//         { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
//       );
//       return response;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       throw err.response.data;
//     }
//   });
// }

// function createAnalytics() {
//   return createAsyncThunk(`${name}/createAnalytics`, async (obj) => {
//     try {
//       const response = await axios.post(
//         url.concat(`/admin/${JSON.parse(localStorage.getItem('user')).id}/create/account`),
//         obj,
//         { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
//       );
//       return response;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       throw err.response.data;
//     }
//   });
// }

function createExtraReducers() {
  return {
    ...fetchAnalytics(),
    // ...deleteAnalytics(),
    // ...updateAnalytics(),
    // ...createAnalytics(),
  };

  function fetchAnalytics() {
    const { pending, fulfilled, rejected } = extraActions.fetchAnalytics;
    return {
      [pending]: (state) => {
        state.analytics = { loading: true, analytics: state?.analytics?.analytics || [] };
      },
      [fulfilled]: (state, action) => {
        state.analytics = { analytics: action.payload.data };
      },
      [rejected]: (state, action) => {
        state.analytics = { error: action.error, analytics: state?.analytics?.analytics || [] };
      },
    };
  }

  // function deleteAnalytics() {
  //   const { pending, fulfilled, rejected } = extraActions.deleteAnalytics;
  //   return {
  //     [pending]: (state) => {
  //       state.allLeads = { loading: true, leads: state?.allLeads?.leads || [] };
  //     },
  //     [fulfilled]: (state, action) => {
  //       console.log(action.payload);

  //       state.allLeads = {
  //         leads: state?.allLeads?.leads?.filter((leads) => leads.id !== action.payload.userIds),
  //       };
  //     },
  //     [rejected]: (state, action) => {
  //       state.allLeads = { error: action.error, leads: state?.allLeads?.leads || [], toast: action?.error?.message };
  //     },
  //   };
  // }

  // function updateAnalytics() {
  //   const { pending, fulfilled, rejected } = extraActions.updateAnalytics;
  //   return {
  //     [pending]: (state) => {
  //       state.user = { loading: true, allUsers: state?.user?.allUsers || [] };
  //     },
  //     [fulfilled]: (state, action) => {
  //       console.log(action);
  //       state.user = {
  //         loadingg: false,
  //         allUsers: state?.user?.allUsers.map((user) =>
  //           user.id === action.payload.data.id ? action.payload.data : user
  //         ),
  //       };
  //     },
  //     [rejected]: (state, action) => {
  //       state.user = {
  //         loadingg: false,
  //         error: action.error,
  //         allUsers: state?.user?.allUsers || [],
  //         toast: action?.error?.message,
  //       };
  //     },
  //   };
  // }
  // function createAnalytics() {
  //   const { pending, fulfilled, rejected } = extraActions.createAnalytics;
  //   return {
  //     [pending]: (state) => {
  //       state.user = { allUsers: state?.user?.allUsers || [] };
  //     },
  //     [fulfilled]: (state, action) => {
  //       console.log(action.payload.data);
  //       state.user.allUsers = [action.payload.data, ...state?.user?.allUsers];
  //     },
  //     [rejected]: (state, action) => {
  //       state.user = {
  //         error: action.error,
  //         allUsers: state?.user?.allUsers || [],
  //         toast: action?.error?.message,
  //       };
  //     },
  //   };
  // }
}

// Reducer

// Actions
export const analyticsActions = { ...slice.actions, ...extraActions };

export default slice.reducer;

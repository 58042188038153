import  {createAsyncThunk, createSlice}  from "@reduxjs/toolkit";
import axios from 'axios';
import { HOST_URL } from '../../config';



const name = "analytics"
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  extraReducers,

});

export const  analyticsDataActions = { ...slice.actions, ...extraActions };
export default slice.reducer;

function createInitialState() {
    return {
        analyticsData: {
            allAnalyticsData:{}, 
        isError:false , 
        isLoading :false , 
      },
    };
  }


function createExtraActions() {
    return {
      getAnalyticsData: getAnalyticsData(),

    };

function getAnalyticsData(){
        return createAsyncThunk(`${name}/getAnalyticsData` ,async()=>{
          try{
           const response = await axios.get(HOST_URL.concat(`admin/config`))
            return response.data.analyticsData
          }
          catch(err){
            throw err.response;
          }
     })
}

}


function createExtraReducers(){
    return{ 
      ...getAnalyticsData(),
  }

  function getAnalyticsData(){

    const { pending, fulfilled, rejected } = extraActions.getAnalyticsData;
    return {
      [pending]:(state)=>{
        state.analyticsData={...state.analyticsData , 
        isLoading:true 
        }
      },
  
      [fulfilled]:(state ,action)=>{
        state.analyticsData={...state.analyticsData , 
            allAnalyticsData:action.payload
        }
  
      },
      [rejected]:(state,action)=>{
        state.analyticsData={...state.analyticsData ,  
          isError:action.error  ,
          allAnalyticsData : [],
          isLoading : false  
        }
      }
  }

   }





}




import { createSlice, createAsyncThunk, createStore } from '@reduxjs/toolkit';
import axios from 'axios';
// import { HOST_URL } from '../../config';
import { HOST_URL } from '../../config';

const name = 'store';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const slice = createSlice({
    name,
    initialState,
    extraReducers,
});

export const advertisementActions = { ...slice.actions, ...extraActions };

export default slice.reducer;

function createInitialState() {
    return {
        isLoading: false,
        advertisements: [],
        error: null,
    };
}

function createExtraActions() {
    return {
        fetchAds: fetchAds(),
        createAds: createAds(),
        updateAds: updateAds(),
        deleteAds: deleteAds(),
    };

    function fetchAds() {
        return createAsyncThunk(`${name}/fetchAds`, async ({ title, status }) =>
            axios.get(
                `${HOST_URL}advertisement/all?name=${title}&status=${status === 'all' ? '' : status
                }`
            )
        );
    }

    function createAds() {

        return createAsyncThunk(`${name}/createAds`, async (values) =>
            axios.post(`${HOST_URL}advertisement`, values)
        );
    }

    function updateAds() {
        
        return createAsyncThunk(`${name}/updateAds`, async (values) =>
            axios.put(`${HOST_URL}advertisement/${values._id}`, values)
        );
    }

    function deleteAds() {

        return createAsyncThunk(`${name}/deleteAds`, async ({id}) =>
            axios.delete(`${HOST_URL}advertisement/${id}`)
        );
    }
}

function createExtraReducers() {
    return {
        ...fetchAdsReducer(),
        ...createAdsReducer(),
        ...updateAdsReducer(),
        ...deleteAdsReducer(),
    };

    function fetchAdsReducer() {
        const { pending, fulfilled, rejected } = extraActions.fetchAds;
        return {
            [pending]: (state) => {
                state.isLoading = true;
            },
            [fulfilled]: (state, action) => {
                state.isLoading = false;
                state.advertisements = action?.payload?.data
            },
            [rejected]: (state, action) => {
                state.isLoading = false;
                state.advertisements = [];
                state.error = action?.payload?.message;
            },
        };
    }

    function createAdsReducer() {
        const { pending, fulfilled, rejected } = extraActions.createAds;
        return {
            [pending]: (state) => {
                state.isLoading = true;
            },
            [fulfilled]: (state, action) => {
                state.isLoading = false;
                state.advertisements = [...state.advertisements, action?.payload?.data];
            },
            [rejected]: (state, action) => {
                state.isLoading = false;
                state.advertisements = [];
                state.error = action?.payload?.message;
            },
        };
    }

    // Reducer for updating Heart Item
    function updateAdsReducer() {
        const { pending, fulfilled, rejected } = extraActions.updateAds;
        return {
            [pending]: (state) => {
                state.isLoading = true;
            },
            [fulfilled]: (state, action) => {
                state.isLoading = false;
                state.advertisements = [...state.advertisements]
            },
            [rejected]: (state, action) => {
                state.isLoading = false;
                state.advertisements = [];
                state.error = action?.payload?.message;
            },
        };
    }

    // Reducer for deleting gift and item
    function deleteAdsReducer() {
        const { pending, fulfilled, rejected } = extraActions.deleteAds;
        return {
            [pending]: (state) => {
                state.isLoading = true;
            },
            [fulfilled]: (state, action) => {
                state.isLoading = false;
                state.advertisements = [...state.advertisements]
            },
            [rejected]: (state, action) => {
                state.isLoading = false;
                state.error = action?.payload?.message;
            },
        };
    }
}

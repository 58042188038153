// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //     { title: 'ecommerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //     { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      // {
      //   title: 'Dashboard',
      //   path: PATH_DASHBOARD.root,
      //   icon: '/assets/illustrations/GreenGreyWheel.svg',
      //   // children: [
      //   //   // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //   //   // { title: 'cards', path: PATH_DASHBOARD.ROOTS_DASHBOARD.user.cards },
      //   //   { title: 'do', path: PATH_DASHBOARD.root },
      //   //   // { title: 'create', path: PATH_DASHBOARD.user.new },
      //   //   // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //   //   // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   // ],
      // },
      // {
      //   title: 'Analytics',
      //   path: PATH_DASHBOARD.analytics.dashboard,
      //   icon: 'mdi:home-analytics' ,
      // },

      {
        title: 'Players',
        path: PATH_DASHBOARD.user.list,
        icon: 'eva:person-fill',
      },
      {
        title: 'Regular Casting Call',
        path: PATH_DASHBOARD.challenge.list,
        icon: 'eva:video-fill',
        // children: [
        //   //   // { title: 'profile', path: PATH_DASHBOARD.user.profile },
        //   //   // { title: 'cards', path: PATH_DASHBOARD.user.cards },
        //   { title: 'list', path: PATH_DASHBOARD.challenge.list, icon: 'eva:list-fill' },
        //   { title: 'create', path: PATH_DASHBOARD.challenge.new, icon: 'eva:folder-add-fill' },
        //   //   // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
        //   //   // { title: 'account', path: PATH_DASHBOARD.user.account },
        // ],
      },

      {
        title: 'Real Casting Call',
        path: PATH_DASHBOARD.realcc.list,
        icon: 'eva:video-fill',
        // children: [
        //   //   // { title: 'profile', path: PATH_DASHBOARD.user.profile },
        //   //   // { title: 'cards', path: PATH_DASHBOARD.user.cards },
        //   { title: 'list', path: PATH_DASHBOARD.challenge.list, icon: 'eva:list-fill' },
        //   { title: 'create', path: PATH_DASHBOARD.challenge.new, icon: 'eva:folder-add-fill' },
        //   //   // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
        //   //   // { title: 'account', path: PATH_DASHBOARD.user.account },
        // ],
      },
      // {
      //   title: 'Store',
      //   path: PATH_DASHBOARD.itemStore.list,
      //   icon: 'material-symbols:store-outline',
      // },
      {
        title: 'Gifts',
        path: PATH_DASHBOARD.gift.list,
        icon: 'material-symbols:featured-seasonal-and-gifts-rounded',
      },
      {
        title: 'Advertisement',
        path: PATH_DASHBOARD.advertisement.list,
        icon: 'eva:tv-fill',
        // icon: 'game-icons:podium-winner',
      },
      {
        title: 'Audio Clips',
        path: PATH_DASHBOARD.audioClips.list,
        icon: 'eva:headphones-fill',
      },
      {
        title: 'Winners',
        path: PATH_DASHBOARD.winners.list,
        icon: 'game-icons:podium-winner',
      },
      {
        title: 'Terms',
        path: PATH_DASHBOARD.terms.condition,
        icon: 'mdi:air-conditioning-off',
      },
      {
        title: 'Admin',
        path: PATH_DASHBOARD.admin.new,
        icon: 'eva:people-fill',
        // children: [
        //   //   // { title: 'profile', path: PATH_DASHBOARD.user.profile },
        //   //   // { title: 'cards', path: PATH_DASHBOARD.user.cards },
        //   // { title: 'list', path: PATH_DASHBOARD.challenge.list, icon: '/assets/illustrations/Analytics.svg' },
        //   { title: 'create', path: PATH_DASHBOARD.admin.new, icon: '/assets/illustrations/SettingsIcon.svg' },
        //   //   // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
        //   //   // { title: 'account', path: PATH_DASHBOARD.user.account },
        // ],
      },

      // {
      //   title: 'Leads',
      //   path: PATH_DASHBOARD.lead.list,
      //   icon: '/assets/illustrations/LeadsIcon.svg',
      // children: [
      //   // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //   // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //   { title: 'list', path: PATH_DASHBOARD.lead.list },
      //   // { title: 'create', path: PATH_DASHBOARD.lead.new },
      //   // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //   // { title: 'account', path: PATH_DASHBOARD.user.account },
      // ],
      // },
      // {
      //   title: 'Analytics',
      //   path: PATH_DASHBOARD.analytics.root,
      //   icon: '/assets/illustrations/Analytics.svg',
      //   children: [
      // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      // {
      //   icon: '/assets/illustrations/Analytics.svg',
      //   title: 'Analytics',
      //   path: PATH_DASHBOARD.analytics.agentsDashboard,
      // },
      // {
      //   icon: '/assets/illustrations/Analytics.svg',
      //   title: 'Account Analytics',
      //   path: PATH_DASHBOARD.analytics.accountsDashboard,
      // },
      // { title: 'create', path: PATH_DASHBOARD.lead.new },
      // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },
      // {
      //   title: 'Accounts Analytics',
      //   path: PATH_DASHBOARD.analytics.accountsDashboard,
      //   icon: '/assets/illustrations/Analytics.svg',
      //   children: [
      //     // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'list', path: PATH_DASHBOARD.analytics.accountsDashboard },
      //     // { title: 'create', path: PATH_DASHBOARD.lead.new },
      //     // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //     // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },
      // {
      //   title: 'Dashboard Settings',
      //   path: '/dashboard/dashboardSettings',
      //   icon: '/assets/illustrations/SettingsIcon.svg',
      // children: [
      //   // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //   // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //   { title: 'list', path: '/dashboard/user/account' },
      //   // { title: 'create', path: PATH_DASHBOARD.lead.new },
      //   // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //   // { title: 'account', path: PATH_DASHBOARD.user.account },
      // ],
      // },
      // {
      //   title: 'Settings',
      //   path: '/dashboard/user/account',
      //   icon: '/assets/illustrations/SettingsIcon.svg',
      //   // children: [
      //   //   // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //   //   // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //   //   { title: 'list', path: '/dashboard/user/account' },
      //   //   // { title: 'create', path: PATH_DASHBOARD.lead.new },
      //   //   // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //   //   // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   // ],
      // },
      // E-COMMERCE
      // {
      //   title: 'ecommerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //   ],
      // },

      // INVOICE
      // {
      //   title: 'invoice',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.invoice.list },
      //     { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //     { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      // },

      // BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //   ],
      // },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">+32</Label>,
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
  //   ],
  // },

  // // DEMO MENU STATES
  // {
  //   subheader: 'Other cases',
  //   items: [
  //     {
  //       // default roles : All roles can see this entry.
  //       // roles: ['user'] Only users can see this item.
  //       // roles: ['admin'] Only admin can see this item.
  //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
  //       // Reference from 'src/guards/RoleBasedGuard'.
  //       title: 'item_by_roles',
  //       path: PATH_DASHBOARD.permissionDenied,
  //       icon: ICONS.menuItem,
  //       roles: ['admin'],
  //       caption: 'only_admin_can_see_this_item',
  //     },
  //     {
  //       title: 'menu_level_1',
  //       path: '#/dashboard/menu_level_1',
  //       icon: ICONS.menuItem,
  //       children: [
  //         { title: 'menu_level_2a', path: '#/dashboard/menu_level_1/menu_level_2a' },
  //         {
  //           title: 'menu_level_2b',
  //           path: '#/dashboard/menu_level_1/menu_level_2b',
  //           children: [
  //             {
  //               title: 'menu_level_3a',
  //               path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3a',
  //             },
  //             {
  //               title: 'menu_level_3b',
  //               path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3b',
  //               children: [
  //                 {
  //                   title: 'menu_level_4a',
  //                   path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3b/menu_level_4a',
  //                 },
  //                 {
  //                   title: 'menu_level_4b',
  //                   path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3b/menu_level_4b',
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     { title: 'item_disabled', path: '#disabled', icon: ICONS.menuItem, disabled: true },
  //     {
  //       title: 'item_label',
  //       path: '#label',
  //       icon: ICONS.menuItem,
  //       info: (
  //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //           NEW
  //         </Label>
  //       ),
  //     },
  //     {
  //       title: 'item_caption',
  //       path: '#caption',
  //       icon: ICONS.menuItem,
  //       caption:
  //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
  //     },
  //     { title: 'item_external_link', path: 'https://www.google.com/', icon: ICONS.menuItem },
  //   ],
  // },
];

export default navConfig;

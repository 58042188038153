import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const slice = createSlice({
  name: 'calendarView',
  initialState,
  reducers: {
    setAllCalendar(state, action) {
      state = action.payload;
      return state;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setAllCalendar } = slice.actions;

// import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { url } from '../../config';

// create slice
const name = 'permissions';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const permissionActions = { ...slice.actions, ...extraActions };
// export const accountsReducer = slice.reducer;
export default slice.reducer;

// implementation

function createInitialState() {
  return {
    permissions: [],
  };
}

function createExtraActions() {
  return {
    fetchPermissions: fetchPermissions(),
  };

  function fetchPermissions() {
    return createAsyncThunk(`${name}/fetchPermissions`, async () =>
      axios.get(url.concat(`/user/${JSON.parse(localStorage.getItem('user')).id}/fetchSavedPermissions`), {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
    );
  }
}
function createExtraReducers() {
  return {
    ...fetchPermissions(),
  };

  function fetchPermissions() {
    const { pending, fulfilled, rejected } = extraActions.fetchPermissions;
    return {
      [pending]: (state) => {
        state.permissions = [];
      },
      [fulfilled]: (state, action) => {
        state.permissions = action.payload.data;
      },
      [rejected]: (state, action) => {
        state.permissions = [];
      },
    };
  }
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: '',
};

const slice = createSlice({
  name: 'adType',
  initialState,
  reducers: {
    setAdType(state, action) {
      state.type = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setAdType } = slice.actions;

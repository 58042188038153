import  {createAsyncThunk, createSlice}  from "@reduxjs/toolkit";
import axios from 'axios';
import { HOST_URL } from '../../config';



const name = "realccData"
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  extraReducers,

});

export const  realccDataActions = { ...slice.actions, ...extraActions };
export default slice.reducer;

function createInitialState() {
    return {
      realccData: {
         allrealccData:[], 
        isError:false , 
        isLoading :false ,
        totalLength:0 , 
        analyticsData:{}
      },
     
    };
  }


function createExtraActions() {
    return {
      getRealData: getRealData(),
      postRealData:postRealData(),
      updateRealData:updateRealData(),
      deleteRealData: deleteRealData(),
      fetchAnalytics:fetchAnalytics() ,

    };

function getRealData(){
        return createAsyncThunk(`${name}/getRealData` ,async({page , limit ,title ,company ,agency ,hashtag ,time ,startDate , endDate})=>{
          // console.log(search , "limit")
          try{
           const response = await axios.get(HOST_URL.concat(`real-challenge?page=${page}&limit=${limit}&title=${title}&company=${company}&agency=${agency}&hashtag=${hashtag}&time=${time}&startDate=${startDate}&endDate=${endDate}`))
          //  console.log(response.data.realChallenge ,"res")
            return response.data.realChallenge
          }
          catch(err){
            throw err.response;
          }
     })
}

function fetchAnalytics() {
  return createAsyncThunk(`${name}/fetchAnalytics`, async () => axios.get(HOST_URL.concat(`admin/config/real-challenge`)));
}



function postRealData(){
  return createAsyncThunk(`${name}/postRealData` ,async(data)=>{
    try{
     const response = await axios.post(HOST_URL.concat(`real-challenge`) , data)
    //  console.log(response?.data ,"resp")
      return await response.data.data
    }
    catch(err){
      // console.log( err.response ,"res----ponse")
      throw err.response.data.message[0];
    }
})
}

function updateRealData(){
  return createAsyncThunk(`${name}/updateRealData` ,async({id , data})=>{
    try{
     const response = await axios.patch(HOST_URL.concat(`real-challenge/${id}`) , data)
    //  console.log(response ,"edit response")
      return response.data.realChallenge
    }
    catch(err){
      throw err.response.data.message;
    }
})
}

function deleteRealData(){
  return createAsyncThunk(`${name}/deleteRealData` ,async(id)=>{
    try{
     const response = await axios.delete(HOST_URL.concat(`real-challenge/${id}`))
      return response.data.realChallenge
    }
    catch(err){
      throw err.response.data.message[0];
    }
})
}



}


function createExtraReducers(){
    return{ 
      ...getRealData(),
      ...postRealData(),
      ...updateRealData(),
      ...deleteRealData(),
      ...fetchAnalytics() ,
  }

  function getRealData(){

    const { pending, fulfilled, rejected } = extraActions.getRealData;
    return {
      [pending]:(state)=>{
        state.realccData={...state.realccData , 
        isLoading:true 
        }
      },
  
      [fulfilled]:(state ,action)=>{
        // console.log(action.payload ,"pay")
        state.realccData={...state.realccData , 
            allrealccData:action.payload.realChallenge,
            totalLength:action.payload.totalCount ,
            isLoading:false 
        }
  
      },
      [rejected]:(state,action)=>{
        state.realccData={...state.realccData ,  
          isError:action.error  ,
          allrealccData : [],
          isLoading : false  
        }
      }
  }

   }

   function fetchAnalytics() {
    const { pending, fulfilled, rejected } = extraActions.fetchAnalytics;
    return {
      [pending]: (state) => {
        state.realccData = {...state.realccData ,  isLoading: true };
      },
      [fulfilled]: (state, action) => {
        // console.log(action.payload ,"payload--")
        state.realccData = {...state.realccData  ,isLoading:false , analyticsData:action.payload?.data?.analyticsData };
      },
      [rejected]: (state, action) => {
        state.realccData = {...state.realccData ,
          isError: true,
          // allrealccData:  [...state.realccData.allrealccData],
          isLoading:false ,
        };
      },
    };
  }
  

   function postRealData(){
    const { pending, fulfilled, rejected } = extraActions.postRealData;
    return {
      [pending]:(state)=>{
        state.realccData={...state.realccData , 
        isLoading:true 
        }
      },
  
      [fulfilled]:(state ,action)=>{
        state.realccData={...state.realccData , 
            // allrealccData:[...state.realccData.allrealccData ]
            isLoading : false  
        }
  
      },
      [rejected]:(state,action)=>{
        state.realccData={...state.realccData ,  
          isError:action.error  ,
          allrealccData : [],
          isLoading : false  
        }
      }
  }
   }

function updateRealData(){
  const { pending, fulfilled, rejected } = extraActions.updateRealData;
return {
   [pending]:(state)=>{
        state.realccData={...state.realccData , 
          isLoading:true ,
        }
      },
  
      [fulfilled]:(state , action)=>{
        state.realccData = {...state.realccData,
            allrealccData:state.realccData.allrealccData.map((item)=> action.payload._id === item._id ? action.payload:item ),
            isLoading:false ,
          }
  
      },
      [rejected]:(state,action)=>{
        state.realccData={...state.realccData,
          isError:action.error  ,
          isLoading : false  ,
        }
      }
}
}

function deleteRealData(){
  const { pending, fulfilled, rejected } = extraActions.deleteRealData ;
  return {
    [pending]:(state)=>{
      state.realccData={...state.realccData , 
      isLoading:true 
      }
    },

    [fulfilled]:(state ,action)=>{
//  console.log(action.payload)
      // const existingData = state.realccData.allrealccData;
      // const existingIndex = existingData.findIndex(item => item.index === action.payload.index);
      state.realccData={...state.realccData , 
      isLoading:false , 
      }
    
     
    },

    [rejected]:(state,action)=>{
      state.realccData={...state.realccData ,  
        isError:action.error  ,
        isLoading : false  
      }
    }
}
}



}




import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { Avatar, ListItemAvatar, ListItemText, MenuItem, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { v4 } from 'uuid';
// import { setAllAgencies } from '../../../redux/slices/allAgencies';

import { url } from '../../../config';
import useAuth from '../../../hooks/useAuth';
import { userActions } from '../../../redux/slices/allUsers';

import { setCurrentUserPermissions } from '../../../redux/slices/currentUserPermissions';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
// components
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import Scrollbar from '../../../components/Scrollbar';
// import { setAllAccounts } from '../../../redux/slices/allAccounts';
import { setAllCalendar } from '../../../redux/slices/allCalendar';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------

export default function ContactsPopover() {
  // const [open, setOpen] = useState(null);
  const { user } = useAuth();

  // const state = useSelector((state) => state);
  // const userData = useSelector((state) => state.allUsers.user.allUser);
  // const currentUser = useSelector((state) => state.currentUserPermissions);
  const dispatch = useDispatch();

  // const handleOpen = (event) => {
  //   setOpen(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setOpen(null);
  // };

  // let allUsers = [];

  useEffect(() => {
    // const getAllUsers = async () => {
    //   try {
    //     const response = await axios.get(url.concat(`/admin/${user.id}/users`), {
    //       headers: { Authorization: `Bearer ${user.token}` },
    //     });
    //     allUsers = response.data;
    //     allUsers = allUsers.map((user) => ({
    //       id: user.id,
    //       avatarUrl: user.profile_photo,
    //       firstName: user.first_name,
    //       lastName: user.last_name,
    //       name: user.first_name,
    //       email: user.email_id,
    //       phoneNumber: user.phone_number,
    //       address: user.address,
    //       country: user.country,
    //       state: user.state,
    //       city: user.city,
    //       zipCode: user.zip_code,
    //       company: user.company,
    //       isVerified: user.verified,
    //       status: user.active,
    //       role: user.account_type,
    //     }));
    //     // setTableData(allUsers);
    //     let filterCurrentUser = allUsers.filter((allUserList) => allUserList.email === user.email_id);
    //     // console.log(' >>> filterCurrentUser', filterCurrentUser);
    //     filterCurrentUser = filterCurrentUser[0];
    //     dispatch(setCurrentUserPermissions(filterCurrentUser));
    //     dispatch(setAllUsers(allUsers));
    //   } catch (err) {
    //     console.error(err);
    //   }
    // };
    // if (user) getAllUsers();
  }, []);

  // useEffect(() => {
  //   const getAllAccounts = async () => {
  //     try {
  //       // const accounts = axios.get(url.concat(`/user/${user.id}/accounts`), {
  //       //   headers: { Authorization: `Bearer ${user.token}` },
  //       // });

  //       // setTableData(allUsers);

  //       const agencies = await axios.get(url.concat(`/user/${user.id}/agencies`), {
  //         headers: { Authorization: `Bearer ${user.token}` },
  //       });
  //       // const response = await Promise.all([accounts, agencies]);
  //       // console.log({ agencies });
  //       // eslint-disable-next-line react-hooks/exhaustive-deps
  //       allAgencies = agencies.data;
  //       allAgencies = allAgencies.map((user) => ({
  //         id: user.id,
  //         avatarUrl: user.profile_photo,
  //         accountContact: user.account_contact,
  //         accountName: user.account_name,
  //         accountType: user.account_type,
  //         phoneNumber: user.phone_number,
  //         active: user.active,
  //         website: user.website,
  //       }));

  //       // allAccounts = response[0].data;
  //       // allAccounts = allAccounts.map((user) => ({
  //       //   id: user.id,
  //       //   avatarUrl: user.profile_photo,
  //       //   accountContact: user.account_contact,
  //       //   accountName: user.account_name,
  //       //   accountType: user.account_type,
  //       //   phoneNumber: user.phone_number,
  //       //   agencyId: user.agency_id,
  //       //   agency:
  //       //     user.agency_id !== undefined ? allAccounts.filter((acc) => acc.id === user.agency_id)[0].account_name : '',
  //       //   active: user.active,
  //       //   website: user.website,
  //       //   createdAt: user.created_at,
  //       //   email_xml: user.email_xml,
  //       //   email_text: user.email_text,
  //       //   webhooks: user.webhooks,
  //       //   provider_name: user.provider_name,
  //       //   lead_source: user.lead_source,
  //       //   agency_whitelabel: user.agency_whitelabel,
  //       //   lead_type: user.lead_type,
  //       //   dealership_type: user.dealership_type,
  //       //   from_email: user.from_email,
  //       //   email_subject: user.email_subject,
  //       // }));

  //       // dispatch(setAllAccounts({ state: 'completed', list: allAccounts }));
  //       dispatch(setAllAgencies(allAgencies));
  //     } catch (error) {
  //       // dispatch(setAllAccounts({ state: 'error', list: allAccounts, error }));
  //       console.error(error);
  //     }
  //   };
  //   getAllAccounts();
  // });

  //   const fetchAnalyticsData = async () => {
  //     try {
  //       let response = await axios.get(url.concat(`/user/${user.id}/analytics/agent`), {
  //         headers: { Authorization: `Bearer ${user.token}` },
  //       });
  //       response = response.data;
  //       const conversion = [];
  //       response.conversions_list.map((key) => {
  //         return conversion.push({
  //           label: key.agent_name,
  //           value: key.conversion_ratio,
  //         });
  //       });
  //       response.conversion = conversion;
  //       console.log(' >>> response', response);
  //       dispatch(setAllAnalytics(response));
  //     } catch (error) {
  //       console.warn(error);
  //     }
  //   };
  //   getAllAccounts();
  //   fetchAnalyticsData();
  // }, []);

  // eslint-disable-next-line no-unused-vars
  // const fetchUserPermissions = async () => {
  //   try {
  //     let response = await axios.get(url.concat(`/user/${user.id}/fetchSavedPermissions`), {
  //       headers: { Authorization: `Bearer ${user.token}` },
  //     });
  //     response = response.data;
  //     // const conversion = [];
  //     // response.conversions_list.map((key) => {
  //     //   return conversion.push({
  //     //     label: key.agent_name,
  //     //     value: key.conversion_ratio,
  //     //   });
  //     // });
  //     // response.conversion = conversion;
  //     // console.log(' >>> response', response);
  //     // dispatch(setAllAnalytics(response));
  //   } catch (error) {
  //     console.warn(error);
  //   }
  // };

  useEffect(() => {
    const getCalendarView = async () => {
      try {
        let response = await axios.get(url.concat(`/leads/calender`), {
          headers: { Authorization: `Bearer ${user.token}` },
        });

        response = response.data;
        dispatch(setAllCalendar(response));
      } catch (err) {
        console.warn(err);
      }
    };
    getCalendarView();
    // fetchUserPermissions();
  }, []);

  // const filterUsers = userData?.filter((user) => user.role === 'AGENT');

  return (
    <div />
    // <>
    //   <IconButtonAnimate
    //     color={open ? 'primary' : 'default'}
    //     onClick={handleOpen}
    //     sx={{
    //       width: 40,
    //       height: 40,
    //       ...(open && {
    //         bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
    //       }),
    //     }}
    //   >
    //     <Iconify icon={'eva:people-fill'} width={20} height={20} />
    //   </IconButtonAnimate>

    //   <MenuPopover
    //     open={Boolean(open)}
    //     anchorEl={open}
    //     onClose={handleClose}
    //     sx={{
    //       mt: 1.5,
    //       ml: 0.75,
    //       width: 320,
    //       '& .MuiMenuItem-root': {
    //         px: 1.5,
    //         height: ITEM_HEIGHT,
    //         borderRadius: 0.75,
    //       },
    //     }}
    //   >
    //     <Typography variant="h6" sx={{ p: 1.5 }}>
    //       Agents <Typography component="span">({filterUsers.length})</Typography>
    //     </Typography>

    //     <Scrollbar sx={{ height: ITEM_HEIGHT * 6 }}>
    //       {userData.map((user) => (
    //         <div key={v4()}>
    //           {user.role === 'AGENT' && (
    //             <>
    //               <MenuItem key={user.id}>
    //                 <ListItemAvatar sx={{ position: 'relative' }}>
    //                   <Avatar src={user.avatarUrl} />
    //                   {/* <BadgeStatus status={contact.status} sx={{ position: 'absolute', right: 1, bottom: 1 }} /> */}
    //                 </ListItemAvatar>

    //                 <ListItemText
    //                   primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
    //                   secondaryTypographyProps={{ typography: 'caption' }}
    //                   primary={user?.name}
    //                   secondary={user?.status === 'offline' && fToNow(user?.lastActivity)}
    //                 />
    //               </MenuItem>
    //             </>
    //           )}
    //         </div>
    //       ))}
    //     </Scrollbar>
    //   </MenuPopover>
    // </>
  );
}

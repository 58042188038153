import * as Yup from 'yup';
import { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Card, Stack, TextField } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { setAllLeadGoals } from '../../../redux/slices/allLeadGoals';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { url } from '../../../config';
import useAuth from '../../../hooks/useAuth';

export default function LeadGoals(accountType) {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const allLeadGoals = useSelector((state) => {
    return state.allLeadGoals;
  });
  const dispatch = useDispatch();

  const [formData, setFormData] = useState([]);

  const NewLeadGoalsSchema = Yup.object().shape({
    dailyleadgoal: Yup.number().required('Required'),
    dailyappointmentgoal: Yup.number().required('Required'),
    weeklyleadgoal: Yup.number().required('Required'),
    weeklyappointmentgoal: Yup.number().required('Required'),
  });

  let accType;
  if (accountType.accountType === 'superAdmin') {
    accType = 'SUPER_ADMIN';
  } else if (accountType.accountType === 'admin') {
    accType = 'ADMIN';
  } else if (accountType.accountType === 'agent') {
    accType = 'AGENT';
  } else if (accountType.accountType === 'public') {
    accType = 'PUBLIC';
  }

  const fetchLeadGoals = async () => {
    let response = await axios.get(url.concat(`/user/${user.id}/fetchSaveleadgoals`), {
      headers: { Authorization: `Bearer ${user.token}` },
    });
    response = response.data;
    const leadGoals = response.filter((res) => res.acctype === accType);
    // setFormData(leadGoals);
    dispatch(setAllLeadGoals(leadGoals));
    reset(leadGoals[0]);
  };

  useEffect(() => {
    fetchLeadGoals();
  }, [accountType]);

  const defaultValues = {
    id: allLeadGoals[0]?.id || '',
    acctype: allLeadGoals[0]?.acctype || '',
    dailyleadgoal: allLeadGoals[0]?.dailyleadgoal,
    dailyappointmentgoal: allLeadGoals[0]?.dailyappointmentgoal,
    weeklyleadgoal: allLeadGoals[0]?.weeklyleadgoal,
    weeklyappointmentgoal: allLeadGoals[0]?.weeklyappointmentgoal,
  };

  const methods = useForm({
    resolver: yupResolver(NewLeadGoalsSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
    register,
  } = methods;

  const values = watch();

  const onSubmit = async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      let request;
      if (data.id) {
        request = [
          {
            id: data.id,
            acctype: data.acctype,
            dailyleadgoal: data.dailyleadgoal,
            dailyappointmentgoal: data.dailyappointmentgoal,
            weeklyleadgoal: data.weeklyleadgoal,
            weeklyappointmentgoal: data.weeklyappointmentgoal,
          },
        ];
        const apiUrl = url.concat(`/user/${user.id}/saveleadgoals`);
        const response = await axios.put(apiUrl, request, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
      } else {
        request = [
          {
            acctype: data.acctype,
            dailyleadgoal: data.dailyleadgoal,
            dailyappointmentgoal: data.dailyappointmentgoal,
            weeklyleadgoal: data.weeklyleadgoal,
            weeklyappointmentgoal: data.weeklyappointmentgoal,
          },
        ];
        const apiUrl = url.concat(`/user/${user.id}/saveleadgoals`);
        const response = await axios.post(apiUrl, request, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
      }
    } catch (error) {
      console.error(error.message);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3, width: '100%' }}>
        <Box
          sx={{
            display: 'grid',
            columnGap: 2,
            rowGap: 3,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
          }}
        >
          <RHFTextField type="number" name="dailyleadgoal" label="Daily Lead Goal" />
          <RHFTextField type="number" name="dailyappointmentgoal" label="Daily Appointment Goal" />
          <RHFTextField type="number" name="weeklyleadgoal" label="Weekly Lead Goal" />
          <RHFTextField type="number" name="weeklyappointmentgoal" label="Weekly Appointment Goal" />
        </Box>
      </Card>
      <Stack alignItems="flex-end" sx={{ mt: 3 }}>
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          Save Changes
        </LoadingButton>
      </Stack>
      {/* <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
        <LoadingButton
          color="inherit"
          size="large"
          variant="contained"
          loading={loadingSave && isSubmitting}
          onClick={handleSubmit(handleSaveAsDraft)}
        >
          Save as Draft
        </LoadingButton>

        <LoadingButton
          size="large"
          variant="contained"
          loading={loadingSend && isSubmitting}
          onClick={handleSubmit(handleCreateAndSend)}
        >
          {isEdit ? 'Update' : 'Create'} & Send
        </LoadingButton>
      </Stack> */}
    </FormProvider>
  );
}

/* eslint-disable no-plusplus */
import PropTypes from 'prop-types';
// @mui
import { List, Box } from '@mui/material';
// hooks
import { useState } from 'react';

import { useSelector } from 'react-redux';
import useLocales from '../../../hooks/useLocales';
//
import { ListSubheaderStyle } from './style';
import NavList from './NavList';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse, ...other }) {
  const { translate } = useLocales();
  const { user } = useAuth();

  const permission = useSelector((state) => state.allPermissions.permissions);
  const userPermissions = permission?.find((data) => data.acctype === user.account_type);
  const canViewUser = userPermissions?.action[0].permissions.includes('view');
  const canViewAccounts = userPermissions?.action[1].permissions.includes('view');
  const canViewLeads = userPermissions?.action[3].permissions.includes('view');
  const canViewAnalytics = userPermissions?.action[4].permissions.includes('view');
  const canViewSettings = userPermissions?.action[5].permissions.includes('view');

  if (canViewUser === false) {
    for (let i = 0; i < navConfig[0].items.length; i++) {
      if (navConfig[0].items[i].title === 'users') {
        navConfig[0].items.splice(i, 1);
      }
    }
  }
  if (canViewAccounts === false) {
    for (let i = 0; i < navConfig[0].items.length; i++) {
      if (navConfig[0].items[i].title === 'accounts') {
        navConfig[0].items.splice(i, 1);
      }
    }
  }
  if (canViewAnalytics === false) {
    for (let i = 0; i < navConfig[0].items.length; i++) {
      if (navConfig[0].items[i].title === 'Analytics') {
        navConfig[0].items.splice(i, 1);
      }
    }
  }
  if (canViewLeads === false) {
    for (let i = 0; i < navConfig[0].items.length; i++) {
      if (navConfig[0].items[i].title === 'Leads') {
        navConfig[0].items.splice(i, 1);
      }
    }
  }
  if (canViewSettings === false) {
    for (let i = 0; i < navConfig[0].items.length; i++) {
      if (navConfig[0].items[i].title === 'Dashboard Settings') {
        navConfig[0].items.splice(i, 1);
      }
    }
  }

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {translate(group.subheader)}
          </ListSubheaderStyle>

          {group.items.map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
            />
          ))}
        </List>
      ))}
    </Box>
  );
}

import PropTypes from 'prop-types';
// @mui
import { TableRow, TableCell } from '@mui/material';
//
import EmptyContent from '../EmptyContent';

// ----------------------------------------------------------------------

TableNoData.propTypes = {
  isNotFound: PropTypes.bool,
};

export default function TableNoData({ isNotFound, msg }) {
  if (isNotFound) msg = 'No Data Found!';
  return (
    <TableRow>
      <TableCell colSpan={7}>
        {msg && (
          <EmptyContent
            title={msg}
            sx={{
              '& span.MuiBox-root': { height: 160 },
            }}
          />
        )}
      </TableCell>
    </TableRow>
  );
}

import  {createAsyncThunk, createSlice}  from "@reduxjs/toolkit";
import axios from 'axios';
import { HOST_URL } from '../../config';



const name = "winners"
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  extraReducers,

});

export const  winnersDataActions = { ...slice.actions, ...extraActions };
export default slice.reducer;

function createInitialState() {
    return {
        winnerData: {
            allWinnerData:[], 
        isError:false , 
        isLoading :false , 
      },
    };
  }


function createExtraActions() {
    return {
      getWinnerData: getWinnerData(),
      // patchWinnerData:patchWinnerData() ,

    };

function getWinnerData(){
        return createAsyncThunk(`${name}/getWinnerData` ,async(id)=>{
          try{
           const response = await axios.get(HOST_URL.concat(`real-casting-call/getWinner/${id}`))
          //  console.log(response?.data ,"data")
            return response.data
          }
          catch(err){
            throw err.response;
          }
     })
}

// function patchWinnerData(){
//     return createAsyncThunk(`${name}/patchWinnerData` ,async({id , data})=>{
//       try{
//        const response = await axios.patch(HOST_URL.concat(`real-challenge/declareWinner/${id}`) , data)
//        console.log(response ,"edit response")
//         return response.data
//       }
//       catch(err){
//         throw err.response.data.message;
//       }
//   })
//   }
  


}


function createExtraReducers(){
    return{ 
      ...getWinnerData(),
      // ...patchWinnerData() ,
  }

  function getWinnerData(){

    const { pending, fulfilled, rejected } = extraActions.getWinnerData;
    return {
      [pending]:(state)=>{
        state.winnerData={...state.winnerData , 
        isLoading:true 
        }
      },
  
      [fulfilled]:(state ,action)=>{
        state.winnerData={...state.winnerData , 
            allWinnerData:action.payload
        }
  
      },
      [rejected]:(state,action)=>{
        state.winnerData={...state.winnerData ,  
          isError:action.error  ,
          allWinnerData : [],
          isLoading : false  
        }
      }
  }

   }

  //  function patchWinnerData(){
  //   const { pending, fulfilled, rejected } = extraActions.patchWinnerData;
  // return {
  //    [pending]:(state)=>{
  //         state.winnerData={...state.winnerData , 
  //           isLoading:true ,
  //         }
  //       },
    
  //       // [fulfilled]:(state , action)=>{
  //       //   state.winnerData = {...state.winnerData,

  //       //   }
    
  //       // },
  //       [rejected]:(state,action)=>{
  //         state.winnerData={...state.winnerData,
  //           isError:action.error  ,
  //           isLoading : false  ,
  //         }
  //       }
  // }
  // }




}




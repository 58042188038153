import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';
import { url } from '../../config';
// const initialState = [];

const name = 'admin';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

export const adminActions = { ...slice.actions, ...extraActions };

export default slice.reducer;

function createInitialState() {
  return {
    admin: { alladmins: [] },
  };
}

function createExtraActions() {
  return {
    createAdmin: createAdmin(),
    // deleteUsers: deleteUsers(),
    // updateUser: updateUser(),
    // createUser: createUser(),
  };
}

// function createAdmin() {
//   return createAsyncThunk(`${name}/createAdmin`, async () => axios.get('https://api.scalexpert.com/users/100/0'));
// }

// function deleteUsers() {
//   return createAsyncThunk(`${name}/deleteUsers`, async (userIds) => {
//     try {
//       const response = await axios.delete(`https://api.scalexpert.com/users/${userIds}`);
//       return response;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       throw err.response.data;
//     }
//   });
// }

// function updateUser() {
//   return createAsyncThunk(`${name}/updateUser`, async (obj) => {
//     try {
//       const response = await axios.patch(
//         url.concat(`/admin/${JSON.parse(localStorage.getItem('user')).id}/profile/update`),
//         obj,
//         { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
//       );
//       return response;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       throw err.response.data;
//     }
//   });
// }

function createAdmin() {
  return createAsyncThunk(`${name}/createAdmin`, async (obj) => {
    try {
      const response = await axios.post(' http://ec2-54-236-58-53.compute-1.amazonaws.com:3000/users/', obj);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      throw err.response.data;
    }
  });
}

function createExtraReducers() {
  return {
    ...createAdmin(),
    // ...deleteUsers(),
    // ...updateUser(),
    // ...createUser(),
  };

  //   function fetchUsers() {
  //     const { pending, fulfilled, rejected } = extraActions.fetchUsers;
  //     return {
  //       [pending]: (state) => {
  //         state.user = { loading: true, allUsers: [] };
  //       },
  //       [fulfilled]: (state, action) => {
  //         state.user = { allUsers: action.payload.data.users };
  //       },
  //       [rejected]: (state, action) => {
  //         state.user = {
  //           error: action.error,
  //           allUsers: [],
  //           toast: { message: action?.error?.message, variant: 'error' },
  //         };
  //       },
  //     };
  //   }

  //   function deleteUsers() {
  //     const { pending, fulfilled, rejected } = extraActions.deleteUsers;
  //     return {
  //       [pending]: (state, action) => {
  //         state.user = {
  //           loading: true,
  //           allUsers: state?.user?.allUsers?.filter((user) => !action?.meta?.arg.includes(user._id)) || [],
  //         };
  //       },
  //       [fulfilled]: (state, action) => {
  //         state.user = {
  //           allUsers: state?.user?.allUsers,
  //           toast: { message: 'Account deletion successfull', variant: 'success' },
  //         };
  //       },
  //       [rejected]: (state, action) => {
  //         state.user = {
  //           error: action.error,
  //           allUsers: state?.user?.allUsers || [],
  //           toast: { message: action?.error?.message, variant: 'error' },
  //         };
  //       },
  //     };
  //   }

  //   function updateUser() {
  //     const { pending, fulfilled, rejected } = extraActions.updateUser;
  //     return {
  //       [pending]: (state) => {
  //         state.user = { loading: true, allUsers: state?.user?.allUsers || [] };
  //       },
  //       [fulfilled]: (state, action) => {
  //         state.user = {
  //           loadingg: false,
  //           allUsers: state?.user?.allUsers.map((user) =>
  //             user.id === action.payload.data.id ? action.payload.data : user
  //           ),
  //           toast: { message: 'User edition successfull', variant: 'success' },
  //         };
  //       },
  //       [rejected]: (state, action) => {
  //         state.user = {
  //           loadingg: false,
  //           error: action.error,
  //           allUsers: state?.user?.allUsers || [],
  //           toast: { message: action?.error?.message, variant: 'error' },
  //         };
  //       },
  //     };
  //   }
  function createAdmin() {
    const { pending, fulfilled, rejected } = extraActions.createAdmin;
    return {
      [pending]: (state) => {
        state.admin = { alladmins: state?.admin?.alladmins || [] };
      },
      [fulfilled]: (state, action) => {
        state.admin = {
          alladmins: [action.payload.data, ...state?.admin?.allad],
          toast: { message: 'Admin creation successfull', variant: 'success' },
        };
      },
      [rejected]: (state, action) => {
        state.admin = {
          error: action.error,
          alladmins: state?.user?.allUsers || [],
          toast: { message: action?.error?.message, variant: 'error' },
        };
      },
    };
  }
}

// Reducer

// Actions

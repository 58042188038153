/* eslint-disable react/prop-types */
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem , Button, Select} from '@mui/material';
// components

import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import { userActions } from '../../../../redux/slices/allUsers';

// ----------------------------------------------------------------------

UserTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function UserTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow, canEdit, canDelete ,handleOpen,
  setSelectedChallenge,handleOpenRegular}) {
    const { picture, username: name, email, role, hearts, label, _id ,ebp ,age ,day ,status,inviteCount ,updatedAt,lastActive} = row;
  const theme = useTheme();
  const { updateUser } = userActions;
  
  
  const [openMenu, setOpenMenuActions] = useState(null);
  const dispatch = useDispatch();
  const [statusValue, setStatusValue] = useState(status);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const CheckStatus = ({ status }) => {
    let text;
    let chipColor;
    // console.log(status);
    if (status === 0) {
      text = 'InActive';
      chipColor = 'warning';
    } else if (status === 1) {
      text = 'Active';
      chipColor = 'success';
    } else if (status === 2) {
      text = 'Blocked';
      chipColor = 'default';
    } else if (status === 3) {
      text = 'Deleted';
      chipColor = 'default';
    }
    return (
      <Label
        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
        color={chipColor}
        sx={{ textTransform: 'capitalize' }}
      >
        {text}
      </Label>
    );
  };

  const handleChange = async(e) => {
    setStatusValue(e.target.value);
  
    const data = {
      status: e.target.value,
    }
    const obj = {
      data,
      userId: _id,
    }
    await dispatch(updateUser(obj))
  };
  // console.log("Pic" , picture)

  
  function getDays(){
    const updatedDate = moment(updatedAt);
    const last = moment(lastActive);

    const remainingDays = updatedDate.diff(last, 'days')
      
    if(remainingDays){
      return `${remainingDays+1} Days`
    }
    
    return `${remainingDays+1} Day`
    

  }

  return (
    <TableRow hover selected={selected}>
      <TableCell sx={{ display: 'flex', alignItems: 'left' }}>
        <Avatar alt={name} src={picture} sx={{ mr: 2 }} />
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell>

      <TableCell align="left">{email || '-'}</TableCell>
      <TableCell align="left">{ebp}</TableCell>
      <TableCell align="left">{_id.slice(_id.length-4, _id.length)}</TableCell>
      <TableCell align="left">{hearts}</TableCell>
      <TableCell>
        <CheckStatus status={status} />
      </TableCell>
      <TableCell>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          size="small"
          value={status}
          label="Status"
          onChange={(e) => handleChange(e)}
        >
          <MenuItem value={0}>Inactive</MenuItem>
          <MenuItem value={1}>Active</MenuItem>
          <MenuItem value={2}>Blocked</MenuItem>
          <MenuItem value={3}>Deleted</MenuItem>
        </Select>
      </TableCell>
      <TableCell align="left">{age}</TableCell>

      {/* <TableCell align="left">{"-"}</TableCell> */}
      
      <TableCell>
        <Button
          variant="outlined"
          onClick={() => {
            handleOpen();
            setSelectedChallenge(_id);
          }}
        >
          <Iconify sx={{ fontSize: '20px' }} icon={'eva:eye-fill'} />
        </Button>
      </TableCell>

      <TableCell>
        <Button
          variant="outlined"
          onClick={() => {
            handleOpenRegular();
            setSelectedChallenge(_id);
          }}
        >
          <Iconify sx={{ fontSize: '20px' }} icon={'eva:eye-fill'} />
        </Button>
      </TableCell>


      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {label}
        </Label>
      </TableCell>

      <TableCell align="left">{inviteCount}</TableCell>
      <TableCell align="left">{status === 1 ? "-" : getDays() }</TableCell>

      <TableCell align="left">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>

              <MenuItem
                onClick={() => {
                  onEditRow(_id);
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}

// import { createSlice } from '@reduxjs/toolkit';

// const initialState = [];

// const slice = createSlice({
//   name: 'toEditAccount',
//   initialState,
//   reducers: {
//     setAllAgencies(state, action) {
//       state = action.payload;
//       return state;
//     },
//   },
// });

// // Reducer
// export default slice.reducer;

// // Actions
// export const { setAllAgencies } = slice.actions;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { url } from '../../config';

// const initialState = [];

const name = 'agency';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

function createInitialState() {
  return {
    allAgencies: [],
  };
}

function createExtraActions() {
  return {
    // fetchAgencies: fetchAgencies(),
  };
}

// function fetchAgencies() {
//   return createAsyncThunk(`${name}/fetchAgencies`, async () =>
//     axios.get(url.concat(`/user/${JSON.parse(localStorage.getItem('user')).id}/agencies`), {
//       headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
//     })
//   );
// }

function createExtraReducers() {
  return {
    // ...fetchAgencies(),
  };

  // function fetchAgencies() {
  //   const { pending, fulfilled, rejected } = extraActions.fetchAgencies;
  //   return {
  //     [pending]: (state) => {
  //       state.allAgencies = { agencies: [] };
  //     },
  //     [fulfilled]: (state, action) => {
  //       state.allAgencies = { agencies: action.payload.data };
  //     },
  //     [rejected]: (state, action) => {
  //       state.allAgencies = { error: action.error, agencies: [] };
  //     },
  //   };
  // }
}

// Reducer

// Actions
export const agencyActions = { ...slice.actions, ...extraActions };

export default slice.reducer;

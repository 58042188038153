import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { url } from '../../config';

// const initialState = [];

const name = 'leads';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

function createInitialState() {
  return {
    allLeads: { leads: [] },
  };
}

function createExtraActions() {
  return {
    // fetchLeads: fetchLeads(),
    // deleteLeads: deleteLeads(),
    // updateLeads: updateLeads(),
    // createLeads: createLeads(),
  };
}

// function fetchLeads() {
//   return createAsyncThunk(`${name}/fetchLeads`, async () =>
//     axios.get(url.concat(`/user/${JSON.parse(localStorage.getItem('user')).id}/leads`), {
//       headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
//     })
//   );
// }

// function deleteLeads() {
//   return createAsyncThunk(`${name}/deleteLeads`, async (userIds) => {
//     try {
//       const response = await axios.delete(
//         url.concat(`/user/${JSON.parse(localStorage.getItem('user')).id}/lead/${userIds}`),
//         {
//           headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
//         }
//       );
//       return { userIds, response };
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       throw err.response.data;
//     }
//   });
// }

// function updateLeads() {
//   return createAsyncThunk(`${name}/updateLeads`, async (obj) => {
//     try {
//       const response = await axios.post(
//         url.concat(`/admin/${JSON.parse(localStorage.getItem('user')).id}/profile/update`),
//         obj,
//         { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
//       );
//       return response;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       throw err.response.data;
//     }
//   });
// }

// function createLeads() {
//   return createAsyncThunk(`${name}/createLeads`, async (obj) => {
//     try {
//       const response = await axios.post(`${url}/user/${JSON.parse(localStorage.getItem('user')).id}/lead`, obj, {
//         headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
//       });
//       return response;
//     } catch (err) {
//       if (!err.response) {
//         throw err;
//       }
//       throw err.response.data;
//     }
//   });
// }

function createExtraReducers() {
  return {
    // ...fetchLeads(),
    // ...deleteLeads(),
    // ...updateLeads(),
    // ...createLeads(),
  };

  // function fetchLeads() {
  //   const { pending, fulfilled, rejected } = extraActions.fetchLeads;
  //   return {
  //     [pending]: (state) => {
  //       state.allLeads = { loading: true, leads: [] };
  //     },
  //     [fulfilled]: (state, action) => {
  //       state.allLeads = { leads: action.payload.data };
  //     },
  //     [rejected]: (state, action) => {
  //       state.allLeads = {
  //         error: action.error,
  //         leads: [],
  //         toast: { message: action?.error?.message, variant: 'error' },
  //       };
  //     },
  //   };
  // }

  // function deleteLeads() {
  //   const { pending, fulfilled, rejected } = extraActions.deleteLeads;
  //   return {
  //     [pending]: (state, action) => {
  //       state.allLeads = {
  //         leads: state?.allLeads?.leads.filter((lead) => lead.id !== action?.meta?.arg) || [],
  //         deletedLeads: state?.allLeads?.leads.filter((lead) => lead.id === action?.meta?.arg),
  //       };
  //     },
  //     [fulfilled]: (state, action) => {
  //       state.allLeads = {
  //         leads: state?.allLeads?.leads?.filter((leads) => leads.id !== action.payload.userIds),
  //         toast: { message: 'Lead deletion successfull', variant: 'success' },
  //       };
  //     },
  //     [rejected]: (state, action) => {
  //       state.allLeads = {
  //         error: action.error,
  //         leads: [...state?.allLeads?.leads, ...state?.allLeads?.deletedLeads] || [],
  //         toast: { message: action?.error?.message, variant: 'error' },
  //       };
  //     },
  //   };
  // }

  // function updateLeads() {
  //   const { pending, fulfilled, rejected } = extraActions.updateLeads;
  //   return {
  //     [pending]: (state) => {
  //       state.allLeads = { loading: true, leads: state?.allLeads?.leads || [] };
  //     },
  //     [fulfilled]: (state, action) => {
  //       state.allLeads = {
  //         loadingg: false,
  //         leads: state?.allLeads?.leads.map((lead) =>
  //           lead.id === action.payload.data.id ? action.payload.data : lead
  //         ),
  //         toast: { message: 'Lead edition successfull', variant: 'success' },
  //       };
  //     },
  //     [rejected]: (state, action) => {
  //       state.allLeads = {
  //         error: action.error,
  //         allUsers: state?.user?.allUsers || [],
  //         toast: { message: action?.error?.message, variant: 'error' },
  //       };
  //     },
  //   };
  // }
  // function createLeads() {
  //   const { pending, fulfilled, rejected } = extraActions.createLeads;
  //   return {
  //     [pending]: (state, action) => {
  //       state.allLeads = { leads: [action?.meta?.arg, ...state?.allLeads?.leads] || [] };
  //     },
  //     [fulfilled]: (state, action) => {
  //       // state.allLeads.leads = [action.payload.data, ...state?.allLeads?.leads];
  //       state.allLeads = {
  //         leads: [action.payload.data, ...state?.allLeads?.leads.splice(1)],
  //         toast: { message: 'Lead creation successful', variant: 'success' },
  //       };
  //       // state.allLeads =  {toast: { message: 'Account creation successfull', variant: 'success' }},
  //     },
  //     [rejected]: (state, action) => {
  //       state.allLeads = {
  //         error: action.error,
  //         leads: state?.allLeads?.leads.splice(1) || [],
  //         toast: { message: action?.error?.message, variant: 'error' },
  //       };
  //     },
  //   };
  // }
}

// Reducer

// Actions
// export const leadActions = { ...slice.actions, ...extraActions };

export default slice.reducer;

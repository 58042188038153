import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const slice = createSlice({
  name: 'toAddAnalytics',
  initialState,
  reducers: {
    setAccountAnalytics(state, action) {
      state = action.payload;
      return state;
    },
  },
});

export default slice.reducer;

export const { setAccountAnalytics } = slice.actions;

// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  itemStore: {
    list: path(ROOTS_DASHBOARD, '/item-store/list'),
    create: path(ROOTS_DASHBOARD, '/item-store/create'),
    edit: (id) => path(ROOTS_DASHBOARD, `/item-store/${id}/edit`),
  },
  gift: {
    list: path(ROOTS_DASHBOARD, '/gift/list'),
    create: path(ROOTS_DASHBOARD, '/gift/create'),
    edit: (id) => path(ROOTS_DASHBOARD, `/gift/${id}/edit`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  challenge: {
    root: path(ROOTS_DASHBOARD, '/challenge'),
    new: path(ROOTS_DASHBOARD, '/challenge/new'),
    list: path(ROOTS_DASHBOARD, '/challenge/list'),
    cards: path(ROOTS_DASHBOARD, '/challenge/cards'),
    profile: path(ROOTS_DASHBOARD, '/challenge/profile'),
    challenge: path(ROOTS_DASHBOARD, '/challenge/challenge'),
    edit: (id) => path(ROOTS_DASHBOARD, `/challenge/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/challenge/list/${id}`),
    demoEdit: path(ROOTS_DASHBOARD, `/challenge/reece-chung/edit`),
    lead: (id) => path(ROOTS_DASHBOARD, `/challenge/${id}/createLead`),
    // casting:path(ROOTS_DASHBOARD, '/challenge/casting-call'),
  },
  realcc: {
    root: path(ROOTS_DASHBOARD, '/realcc'),
    new: path(ROOTS_DASHBOARD, '/realcc/new'),
    list: path(ROOTS_DASHBOARD, '/realcc/list'),
    cards: path(ROOTS_DASHBOARD, '/realcc/cards'),
    profile: path(ROOTS_DASHBOARD, '/realcc/profile'),
    challenge: path(ROOTS_DASHBOARD, '/realcc/realcc'),
    userlist: (id) => path(ROOTS_DASHBOARD, `/realcc/${id}/userlist`),
    pdf: (id) => path(ROOTS_DASHBOARD, `/realcc/${id}/pdf`),
    edit: (id) => path(ROOTS_DASHBOARD, `/realcc/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/realcc/list/${id}`),
    demoEdit: path(ROOTS_DASHBOARD, `/realcc/reece-chung/edit`),
    lead: (id) => path(ROOTS_DASHBOARD, `/realcc/${id}/createLead`),
    // casting:path(ROOTS_DASHBOARD, '/realcc/casting'),
  },
  winners: {
    root: path(ROOTS_DASHBOARD, '/winners'),
    list: path(ROOTS_DASHBOARD, '/winners/list'),
    realccList: (id) => path(ROOTS_DASHBOARD, `/winners/${id}/realccList`),
  },
  terms: {
    root: path(ROOTS_DASHBOARD, '/terms'),
    condition: path(ROOTS_DASHBOARD, '/terms/condition'),
  },
  analytics: {
    dashboard: path(ROOTS_DASHBOARD, '/analytics'),
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    new: path(ROOTS_DASHBOARD, '/admin/new'),
  },
  advertisement: {
    list: path(ROOTS_DASHBOARD, '/advertisement/list'),
    create: path(ROOTS_DASHBOARD, '/advertisement/create'),
    edit: (id) => path(ROOTS_DASHBOARD, `/advertisement/${id}/edit`),
  },

  audioClips: {
    list: path(ROOTS_DASHBOARD, '/audioClips/list'),
    create: path(ROOTS_DASHBOARD, '/audioClips/create'),
    edit: (id) => path(ROOTS_DASHBOARD, `/audioClips/${id}/edit`),
  },

  lead: {
    root: path(ROOTS_DASHBOARD, '/lead'),
    list: path(ROOTS_DASHBOARD, '/lead/list'),
    new: path(ROOTS_DASHBOARD, '/lead/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/lead/${id}/edit`),
  },
  client: {
    root: path(ROOTS_DASHBOARD, '/clientDashboard/view'),
    clientDashboard: path(ROOTS_DASHBOARD, '/clientDashboard/view'),
    view: (id) => path(ROOTS_DASHBOARD, `/clientDashboard/${id}/view`),
  },
  // analytics: {
  //   root: path(ROOTS_DASHBOARD, '/analytics/view'),
  //   agentsDashboard: path(ROOTS_DASHBOARD, '/analytics/agents'),
  //   accountsDashboard: path(ROOTS_DASHBOARD, '/analytics/accounts'),
  //   accountsDashboardSpecific: (id) => path(ROOTS_DASHBOARD, `/analytics/accounts?id=${id}`),
  //   clientDashboard: path(ROOTS_DASHBOARD, '/analytics/view'),
  //   view: (id) => path(ROOTS_DASHBOARD, `/clientDashboard/${id}/view`),
  // },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

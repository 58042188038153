import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  Card,
  Container,
  Typography,
  CardHeader,
  ToggleButton,
  ToggleButtonGroup,
  ListItem,
  ListItemButton,
  ListItemIcon,
  List,
  Tab,
  Tabs,
  Grid,
} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
// hooks
import useSettings from '../../hooks/useSettings';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// guards
import RoleBasedGuard from '../../guards/RoleBasedGuard';
import DashboardPermissions from '../../layouts/dashboard/DashboardSetting/Permmisions';
import LeadGoals from '../../layouts/dashboard/DashboardSetting/LeadGoals';
import WebsiteIdentity from '../../layouts/dashboard/DashboardSetting/WebsiteIdentity';
import axios from '../../utils/axios';
import useAuth from '../../hooks/useAuth';
import { url } from '../../config';

export default function DashboardSettingView() {
  const { themeStretch } = useSettings();
  const [accountType, setAccountType] = useState('superAdmin');
  const [role, setRole] = useState('Permissions');

  const handleChangeRole = (event, newRole) => {
    if (newRole !== null) {
      setRole(newRole);
    }
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Page title="Dashboard Settings">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Dashboard Settings"
          links={[
            {
              name: 'Dashboard',
              href: PATH_DASHBOARD.root,
            },
            { name: role },
          ]}
        />

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={role} onChange={handleChangeRole} aria-label="basic tabs example">
            <Tab label="Permissions" value="Permissions" sx={{ width: '33%' }} />
            <Tab label="Lead Goals" value="Lead Goals" sx={{ width: '33%' }} />
            <Tab label="Website Identity" value="Website Identity" sx={{ width: '34%' }} />
          </Tabs>
        </Box>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Box sx={{ width: '100%', maxWidth: 260, bgcolor: 'background.paper', borderRight: 0.5 }}>
            <nav aria-label="main mailbox folders">
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => setAccountType('superAdmin')}
                    sx={{
                      px: 3,
                      height: 48,
                      typography: 'body2',
                      color: accountType === 'superAdmin' ? '#00ab55' : 'text.secondary',
                      textTransform: 'capitalize',
                      bgcolor: accountType === 'superAdmin' ? 'action.selected' : null,
                      fontWeight: accountType === 'superAdmin' ? 'fontWeightMedium' : null,
                    }}
                  >
                    <ListItemText primary="Super Admin" />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => setAccountType('admin')}
                    sx={{
                      px: 3,
                      height: 48,
                      typography: 'body2',
                      color: accountType === 'admin' ? '#00ab55' : 'text.secondary',
                      textTransform: 'capitalize',
                      bgcolor: accountType === 'admin' ? 'action.selected' : null,
                      fontWeight: accountType === 'admin' ? 'fontWeightMedium' : null,
                    }}
                  >
                    <ListItemText primary="Admin" />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => setAccountType('agent')}
                    sx={{
                      px: 3,
                      height: 48,
                      typography: 'body2',
                      color: accountType === 'agent' ? '#00ab55' : 'text.secondary',
                      textTransform: 'capitalize',
                      bgcolor: accountType === 'agent' ? 'action.selected' : null,
                      fontWeight: accountType === 'agent' ? 'fontWeightMedium' : null,
                    }}
                  >
                    <ListItemText primary="Agent" />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => setAccountType('public')}
                    sx={{
                      px: 3,
                      height: 48,
                      typography: 'body2',
                      color: accountType === 'public' ? '#00ab55' : 'text.secondary',
                      textTransform: 'capitalize',
                      bgcolor: accountType === 'public' ? 'action.selected' : null,
                      fontWeight: accountType === 'public' ? 'fontWeightMedium' : 'fontWeightsmall',
                    }}
                  >
                    <ListItemText primary="Public" />
                  </ListItemButton>
                </ListItem>
              </List>
            </nav>
          </Box>
          <Box style={{ margin: '5% 2%' }}>
            {role === 'Permissions' && (
              <>
                <DashboardPermissions accountType={accountType} />
              </>
            )}
            {role === 'Lead Goals' && (
              <>
                <LeadGoals accountType={accountType} />
              </>
            )}
            {role === 'Website Identity' && <WebsiteIdentity />}
          </Box>
        </Grid>
      </Container>
    </Page>
  );
}

import  {createAsyncThunk, createSlice}  from "@reduxjs/toolkit";
import axios from 'axios';
import { HOST_URL } from '../../config';



const name = "realccDataUser"
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  extraReducers,

});

export const  realccUserDataActions = { ...slice.actions, ...extraActions };
export default slice.reducer;

function createInitialState() {
    return {
      realccUserData: {
         allrealccUserData:{}, 
        isError:false , 
        isLoading :false ,
        totalLength:0 , 
        analyticsData:{}
      },
     
    };
  }


function createExtraActions() {
    return {
      getRealDataUser: getRealDataUser(),
      fetchAnalyticsUser:fetchAnalyticsUser() ,
      updateRealDataUser:updateRealDataUser()

    };

function getRealDataUser(){
        return createAsyncThunk(`${name}/getRealDataUser` ,async({id ,page , limit , name, email,status, label,fromAge,toAge})=>{
          // console.log(search , "limit")
          try{
           const response = await axios.get(HOST_URL.concat(`real-challenge/userData/${id}?page=${page}&limit=${limit}&name=${name}&email=${email}&status=${status}&label=${label}&fromAge=${fromAge}&toAge=${toAge}`))
        //    console.log(response.data.realChallenge ,"data")
           return response.data.realChallenge
          }
          catch(err){
            throw err.response;
          }
     })
}

function fetchAnalyticsUser() {
  return createAsyncThunk(`${name}/fetchAnalyticsUser`, async (id) => axios.get(HOST_URL.concat(`admin/config/real-challenge/userData/${id}`)));
}

function updateRealDataUser(){
  return createAsyncThunk(`${name}/updateRealData` ,async({data , userId ,  challengeId})=>{
    try{
     const response = await axios.patch(HOST_URL.concat(`real-casting-call/${userId}/${challengeId}`) , data)
    //  console.log(response ,"edit response")
      return response.data
    }
    catch(err){
      throw err.response.data.message;
    }
})
}




}


function createExtraReducers(){
    return{ 
      ...getRealDataUser(),
      ...fetchAnalyticsUser() ,
      ...updateRealDataUser() ,
  }

  function getRealDataUser(){

    const { pending, fulfilled, rejected } = extraActions.getRealDataUser;
    return {
      [pending]:(state)=>{
        state.realccUserData={...state.realccUserData , 
        isLoading:true 
        }
      },
  
      [fulfilled]:(state ,action)=>{
        // console.log(action.payload ,"pay")
        state.realccUserData={...state.realccUserData , 
            allrealccUserData:action.payload?.realChallengeData , 
            totalLength:action.payload.totalCount,
            isLoading:false 
        }
  
      },
      [rejected]:(state,action)=>{
        state.realccUserData={...state.realccUserData ,  
          isError:action.error  ,
          allrealccUserData : {},
          isLoading : false  
        }
      }
  }

   }

   function fetchAnalyticsUser() {
    const { pending, fulfilled, rejected } = extraActions.fetchAnalyticsUser;
    return {
      [pending]: (state) => {
        state.realccUserData = {...state.realccUserData ,  isLoading: true,  };
      },
      [fulfilled]: (state, action) => {
        // console.log(action.payload ,"payload--")
        state.realccUserData = {...state.realccUserData  ,isLoading:false , analyticsData:action.payload?.data?.analyticsData };
      },
      [rejected]: (state, action) => {
        state.realccUserData = {...state.realccUserData ,
          isError: true,
        //   allrealccUserData:  [...state.realccUserData.allrealccUserData],
          isLoading:false ,
        };
      },
    };
  }
  

  function updateRealDataUser(){
    const { pending, fulfilled, rejected } = extraActions.updateRealDataUser;
  return {
     [pending]:(state)=>{
          state.realccUserData={...state.realccUserData , 
            isLoading:true ,
          }
        },
    
        [fulfilled]:(state , action)=>{
          // console.log(state.realccUserData ,"paylaod")
          state.realccUserData = {...state.realccUserData,
            // allrealccUserData:state.realccUserData.allrealccUserData?.invitedUsers?.map((item)=> action.payload._id === item._id ? action.payload:item ),
            // allrealccUserData:state.realccUserData.allrealccUserData?.invitedUsers?.realCastingCalls?.map((item)=> action.payload._id === item._id ? action.payload:item ),
            isLoading:false ,
          }
    
        },
        [rejected]:(state,action)=>{
          state.realccUserData={...state.realccUserData,
            isError:action.error  ,
            isLoading : false  ,
          }
        }
  }
  }
  




}




/* eslint-disable react/prop-types */
import FullCalendar from '@fullcalendar/react'; // => request placed at the top
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';

//
import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

// @mui
import { Card, Button, Container, DialogTitle, Divider, Stack } from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getEvents, openModal, closeModal, updateEvent, selectEvent, selectRange } from '../../redux/slices/calendar';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { DialogAnimate } from '../../components/animate';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import { CalendarForm, CalendarStyle, CalendarToolbar } from '../../sections/@dashboard/calendar';
import InvoiceAnalytic from '../../sections/@dashboard/invoice/InvoiceAnalytic';

// ----------------------------------------------------------------------

const selectedEventSelector = (state) => {
  const { events, selectedEventId } = state.calendar;
  if (selectedEventId !== null) {
    const filteredLead = state?.allCalendar?.lead_list?.filter((lead) => lead.lead_name === selectedEventId);
    return filteredLead ? filteredLead[0] : null;
  }
};

export default function Calendar({ tableData, calendarDataInfo }) {
  const theme = useTheme();

  const dispatch = useDispatch();

  const isDesktop = useResponsive('up', 'sm');

  const calendarRef = useRef(null);

  const [date, setDate] = useState(new Date());

  const [view, setView] = useState(isDesktop ? 'dayGridMonth' : 'listWeek');

  const selectedEvent = useSelector(selectedEventSelector);

  const [calendarData, setCalendarData] = useState([]);

  useEffect(() => {
    // handleAddEvent();
  }, [selectedEvent]);

  const GetFormattedDate = (date) => {
    const dateTime = new Date(date);
    const month = (dateTime.getMonth() + 1 < 10 ? '0' : '') + (dateTime.getMonth() + 1);
    const day = (dateTime.getDate() < 10 ? '0' : '') + dateTime.getDate();
    const year = dateTime.getFullYear();
    // const hour = (dateTime.getHours() < 10 ? '0' : '') + dateTime.getHours();
    // const minute_ = (dateTime.getMinutes() < 10 ? '0' : '') + dateTime.getMinutes();
    // const second_ = (dateTime.getSeconds() < 10 ? '0' : '') + dateTime.getSeconds();
    return `${year}-${month}-${day}`;
  };

  // const COLOR_OPTIONS = ['#00AB55', '#1890FF', '#54D62C', '#FFC107', '#FF4842', '#04297A', '#7A0C2E'];

  const COLOR_MAPPING = {
    appointments: '#54D62C',
    voucher: '##FFC107',
    reschedule: '#FF4842',
  };

  useEffect(() => {
    const calendarData = [];
    if (tableData.length > 0) {
      for (let i = 0; i < tableData?.length; i += 1) {
        let leadType;
        if (tableData[i].appointment_datetime === null) {
          leadType = 'voucher';
        } else {
          leadType = 'appointments';
        }
        // new Date(tableData[i].appointmentDatetime) > new Date();
        calendarData.push({
          date: GetFormattedDate(tableData[i].appointment_datetime),
          title: tableData[i].lead_name,
          textColor: COLOR_MAPPING[leadType],
        });
      }
      setCalendarData(calendarData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const isOpenModal = useSelector((state) => state.calendar.isOpenModal);
  const { appointments, reschedules, lead_list: leadList, vouchers } = calendarDataInfo;
  // useSelector((state) => state.allCalendar);
  const leads = leadList?.length;
  const selectedRange = false;

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = isDesktop ? 'dayGridMonth' : 'listWeek';
      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [isDesktop]);

  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleSelectRange = (arg) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }
    dispatch(selectRange(arg.start, arg.end));
  };

  const handleSelectEvent = (arg) => {
    dispatch(selectEvent(arg.event._def.title));
  };

  const handleResizeEvent = async ({ event }) => {
    try {
      dispatch(
        updateEvent(event.id, {
          allDay: event.allDay,
          start: event.start,
          end: event.end,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <Page title="Calendar">
        <CalendarStyle sx={{ pl: '0 ' }}>
          <CalendarToolbar
            date={date}
            view={view}
            onNextDate={handleClickDateNext}
            onPrevDate={handleClickDatePrev}
            onToday={handleClickToday}
            onChangeView={handleChangeView}
          />
          <FullCalendar
            weekends
            editable
            droppable
            selectable
            events={calendarData}
            ref={calendarRef}
            rerenderDelay={10}
            initialDate={date}
            initialView={view}
            dayMaxEventRows={3}
            eventDisplay="block"
            headerToolbar={false}
            allDayMaintainDuration
            eventResizableFromStart
            // select={handleSelectRange}
            // eventDrop={handleDropEvent}
            eventClick={handleSelectEvent}
            // eventResize={handleResizeEvent}
            height={isDesktop ? 720 : 'auto'}
            plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
          />
        </CalendarStyle>

        <DialogAnimate open={isOpenModal} onClose={handleCloseModal}>
          <DialogTitle>{selectedEvent && 'Lead Details'}</DialogTitle>

          <CalendarForm selectedEvent={selectedEvent || {}} range={selectedRange} onCancel={handleCloseModal} />
        </DialogAnimate>
      </Page>

     
    </>
  );
}

import * as Yup from 'yup';
import { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Card, Stack, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
// utils
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { setWebsiteIdentity } from '../../../redux/slices/websiteIdentity';
import { fData } from '../../../utils/formatNumber';
import { FormProvider, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';
import { url } from '../../../config';
import useAuth from '../../../hooks/useAuth';
import Label from '../../../components/Label';

export default function WebsiteIdentity() {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const websiteIdentityData = useSelector((state) => state.websiteIdentity);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState([]);

  const websiteIdentitySchema = Yup.object().shape({
    softwarename: Yup.string().required('Required'),
    tagline: Yup.string(),
    faviconurl: Yup.mixed(),
    logourl: Yup.mixed(),
  });

  const fetchWebsiteIdentity = async () => {
    let response = await axios.get(url.concat(`/fetchWebsiteIdentity`), {
      headers: { Authorization: `Bearer ${user.token}` },
    });
    response = response.data;
    // setFormData(response);
    dispatch(setWebsiteIdentity(response));
    reset(response);
  };

  useEffect(() => {
    fetchWebsiteIdentity();
  }, []);

  const defaultValues = {
    id: websiteIdentityData?.id || '',
    softwarename: websiteIdentityData?.softwarename || '',
    tagline: websiteIdentityData?.tagline || '',
    faviconurl: websiteIdentityData?.faviconurl || '',
    logourl: websiteIdentityData?.logourl || '',
  };

  const methods = useForm({
    resolver: yupResolver(websiteIdentitySchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
    // register,
  } = methods;

  const onSubmit = async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));

      let faviconUrl = '';
      if (data?.faviconurl !== null && data?.faviconurl !== undefined) {
        if (typeof data?.faviconurl === 'object') {
          const formData = new FormData();
          formData.append('file', data?.faviconurl);
          const response = await axios.post(url.concat(`/users/document/IMAGE/upload`), formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${user.token}`,
            },
          });
          faviconUrl = response.data.url;
        } else {
          faviconUrl = data?.faviconurl;
        }
      }

      let logoUrl = '';
      if (data?.logourl !== null && data?.logourl !== undefined) {
        if (typeof data?.logourl === 'object') {
          const formData = new FormData();
          formData.append('file', data?.logourl);
          const response = await axios.post(url.concat(`/users/document/IMAGE/upload`), formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${user.token}`,
            },
          });
          logoUrl = response.data.url;
        } else {
          logoUrl = data?.logourl;
        }
      }

      const request = {
        id: data.id,
        softwarename: data.softwarename,
        tagline: data.tagline,
        faviconurl: faviconUrl,
        logourl: logoUrl,
      };
      const apiUrl = url.concat(`/saveWebsiteIdentity`);
      const response1 = await axios.put(apiUrl, request, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
    } catch (error) {
      console.error(error.message);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'faviconurl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const handleDrop2 = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'logourl',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3, width: '100%' }}>
        <Box
          sx={{
            display: 'grid',
            columnGap: 2,
            rowGap: 3,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
          }}
        >
          <RHFTextField type="string" name="softwarename" label="Software Name" />
          <RHFTextField type="string" name="tagline" label="Tagline" />
          <span>
            <Label
              sx={{
                display: 'flex',
                margin: '0 0 5px 0',
              }}
            >
              Favicon
            </Label>
            <RHFUploadAvatar
              name="faviconurl"
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.secondary',
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of {fData(3145728)}
                </Typography>
              }
            />
          </span>
          <span>
            <Label
              sx={{
                display: 'flex',
                margin: '0 0 5px 0',
              }}
            >
              Logo
            </Label>
            <RHFUploadAvatar
              name="logourl"
              maxSize={3145728}
              onDrop={handleDrop2}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.secondary',
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of {fData(3145728)}
                </Typography>
              }
            />
          </span>
        </Box>
      </Card>
      <Stack alignItems="flex-end" sx={{ mt: 3 }}>
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          Save Changes
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  let dynamicURL;
  if (window.location.href.includes('localhost')) {
    dynamicURL = 'http://localhost:3001/';
  } else {
    dynamicURL = 'https://Instasleb.convergix.io/';
  }

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          {/* <Image
            style={{ width: '15vw', margin: 0, padding: 0, position: 'relative' }}
            visibleByDefault
            disabledEffect
            src="/assets/illustrations/defaultLogoBig.png"
            alt="Logo"
          /> */}
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" style={{ position: 'absolute', top: '-5vh' }} sx={{ px: 5, mt: 20, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <Image
              style={{ marginTop: '18vh' }}
              visibleByDefault
              disabledEffect
              src="/assets/illustrations/illustration_login.png"
              alt="login"
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in to CelebPups
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
              </Box>

              {/* <Tooltip title={capitalCase(method)} placement="right">
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    width="32.0257pt"
                    height="32.6344pt"
                    viewBox="0 0 16.0257 16.6344"
                  >
                    <g enableBackground="new">
                      <g>
                        <clipPath id="cp0">
                          <path
                            transform="matrix(1,0,0,-1,0,16.6344)"
                            d="M 0 16.634 L 16.026 16.634 L 16.026 0 L 0 0 Z "
                          />
                        </clipPath>
                        <g clipPath="url(#cp0)">
                          <g opacity=".5">
                            <clipPath id="cp1">
                              <path
                                transform="matrix(1,0,0,-1,0,16.6344)"
                                d="M 1.91585 12.2127 L 5.86465 12.2127 L 5.86465 16.016 L 1.91585 16.016 Z "
                              />
                            </clipPath>
                            <g clipPath="url(#cp1)">
                              <path
                                transform="matrix(1,0,0,-1,5.8647,2.8748999)"
                                d="M 0 0 L -.937 2.257 C -2.107 1.766 -3.137 1.005 -3.949 .053 L -1.939 -1.547 C -1.418 -.885 -.754 -.354 0 0 "
                                fill="#505150"
                              />
                            </g>
                          </g>
                          <g opacity=".75">
                            <clipPath id="cp2">
                              <path
                                transform="matrix(1,0,0,-1,0,16.6344)"
                                d="M 5.22183 13.6553 L 11.376 13.6553 L 11.376 16.6344 L 5.22183 16.6344 Z "
                              />
                            </clipPath>
                            <g clipPath="url(#cp2)">
                              <path
                                transform="matrix(1,0,0,-1,8.0129,2.3916999)"
                                d="M 0 0 C .845 0 1.642 -.215 2.353 -.587 L 3.363 1.649 C 2.34 2.125 1.201 2.392 0 2.392 C -.981 2.392 -1.921 2.214 -2.791 1.889 L -1.857 -.361 C -1.278 -.131 -.654 0 0 0 "
                                fill="#4f5050"
                              />
                            </g>
                          </g>
                          <g opacity=".25">
                            <clipPath id="cp3">
                              <path
                                transform="matrix(1,0,0,-1,0,16.6344)"
                                d="M .965286 11.2306 L 3.74068 11.2306 L 3.74068 13.5677 L .965286 13.5677 Z "
                              />
                            </clipPath>
                            <g clipPath="url(#cp3)">
                              <path
                                transform="matrix(1,0,0,-1,3.7407,4.678499)"
                                d="M 0 0 L -2.025 1.612 C -2.305 1.256 -2.558 .877 -2.775 .476 L -.435 -.725 C -.308 -.471 -.161 -.23 0 0 "
                                fill="#4f5050"
                              />
                            </g>
                          </g>
                        </g>
                        <clipPath id="cp4">
                          <path
                            transform="matrix(1,0,0,-1,0,16.6344)"
                            d="M 10.643 13.501 C 12.246 12.532 13.332 10.71 13.332 8.622 C 13.332 5.522 10.946 3.001 8.013 3.001 C 5.08 3.001 2.694 5.522 2.694 8.622 C 2.694 9.45 2.868 10.234 3.174 10.943 L .821 12.15 C .296 11.085 0 9.887 0 8.622 C 0 6.511 .823 4.588 2.162 3.155 C 1.864 2.613 .408 -.054 .573 .001 C .711 .047 3.294 .936 4.585 1.381 C 5.625 .887 6.787 .608 8.013 .608 C 12.431 .608 16.025 4.203 16.025 8.622 C 16.025 11.727 14.249 14.423 11.66 15.753 Z "
                          />
                        </clipPath>
                        <g clipPath="url(#cp4)">
                          <image
                            x="0"
                            y="0"
                            width="17"
                            height="17"
                            xlinkHref="data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAACXBIWXMAAA7EAAAO
xAGVKw4bAAAA00lEQVR4nJ2TwRICIQhAd3+5W7/XB9WpWhE0EHSZxnbNgwMX3vAA
18vtmmOkBfhFsoeJY9I8pQUk5xhI843zN8cnx3vKyyoQACyFgLgXk4KggHIpLi8p
ZOtBAKUDKlEeJuvGOlFAVoh18/IQ0amAnk60DgQUznSkqIFmdPxMPAi80oiOAhTY
ZmLdnOr8WjG4Ff+ts89mUqfFr+FO60RXHA1wqtNbcUCa10G34mGdo4sNtF9thRzq
VAi0Tmjs7/yaieShKOjp164C5xXyGNXxh9bT+QB5GFC4Ny991AAAAABJRU5ErkJggg=="
                          />
                        </g>
                        <clipPath id="cp5">
                          <path
                            transform="matrix(1,0,0,-1,0,16.6344)"
                            d="M 0 16.634 L 16.026 16.634 L 16.026 0 L 0 0 Z "
                          />
                        </clipPath>
                        <g clipPath="url(#cp5)">
                          <path
                            transform="matrix(1,0,0,-1,9.095,8.3094)"
                            d="M 0 0 C 0 -.598 -.485 -1.082 -1.082 -1.082 C -1.68 -1.082 -2.164 -.598 -2.164 0 C -2.164 .598 -1.68 1.082 -1.082 1.082 C -.485 1.082 0 .598 0 0 M -5.706 1.393 C -5.84 .946 -5.91 .478 -5.91 0 C -5.91 -.354 -5.872 -.703 -5.797 -1.042 C -3.55 -.256 -1.546 -2.138 -2.459 -4.628 C -2.017 -4.76 -1.554 -4.828 -1.082 -4.828 C -.61 -4.828 -.147 -4.76 .295 -4.628 C -.619 -2.138 1.386 -.256 3.633 -1.042 C 3.707 -.703 3.746 -.354 3.746 0 C 3.746 .478 3.676 .946 3.541 1.393 C 2.196 1.313 .697 2.343 -1.082 2.327 C -2.861 2.343 -4.36 1.313 -5.706 1.393 "
                            fill="#4f5050"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </>
              </Tooltip> */}
            </Stack>

            {/* <Alert severity="info" sx={{ mb: 3 }}>
              Use email : <strong>demo@minimals.cc</strong> / password :<strong> demo1234</strong>
            </Alert> */}

            <LoginForm />

            {/* {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  Get started
                </Link>
              </Typography>
            )} */}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

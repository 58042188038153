import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { url } from '../config';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const userID = JSON.parse(localStorage.getItem('user'));
        const response = await axios.get(`https://api.scalexpert.com/users/${userID}`);
        console.log({ response });
        if (
          localStorage.getItem('accessToken') &&
          response &&
          response.data &&
          response.data.user &&
          response.data.user._id &&
          response.data.user.email &&
          response.data.user.roleData &&
          response.data.user.roleData.role &&
          (response.data.user.roleData.role === 'admin' || response.data.user.roleData.role === 'moderator')
        ) {
          const { user } = {
            user: {
              token:
                'eyJhbGciOiJSUzI1NiIsImtpZCI6InRCME0yQSJ9.eyJpc3MiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGUuY29tLyIsImF1ZCI6InByb2QtbWluaW1hbC11aS1raXQiLCJpYXQiOjE2NjY2ODA0NTIsImV4cCI6MTY2Nzg5MDA1MiwidXNlcl9pZCI6ImZib0NyY2N6SHJUTkxIa0lhRnRldmwyWmVvZDIiLCJlbWFpbCI6ImRlbW9AbWluaW1hbHMuY2MiLCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQiLCJ2ZXJpZmllZCI6ZmFsc2V9.fWWYAclLKX2nhLs4QBnb8OyLrbIuEMFf_DtITOK-i-o5iBu___lsYxMg10TRbIIl4LcfVWoa93pt8K0qQZTASHq8z8yqDOpWAgX7CQvX5XAPMvdaT5SlC58Av3plGOcmPAHO1M04085-ppdsdw1oZZXONuetKkJYy6qTWvLaSt2mJWXGZaf4hqQD7cvUANpEUINpAy9NHwH0OzPssUeJZFwIIvUx93euWDzDmVt9rABUG2MKS32N7yTDHANyoqCnKT0AFrUCIEspFGKcHsx3uqBxSXqfise49gu6xK7mHmU_pqoNW73DQB1zJNCESD83Bhk21n2UQ2_XpsrtL_PTMQ',
              id: response?.data?.user?._id,
              displayName: response?.data?.user?.username,
              email: response?.data?.user?.email,
              password: response?.data?.user?.password,
              photoURL: 'https://minimal-assets-api-dev.vercel.app/assets/images/avatars/avatar_default.jpg',
              status: response?.data?.user?.status,
              role: response?.data?.user?.role,
              roleData: response?.data?.user?.roleData,
            },
          };
          setSession(localStorage.getItem('accessToken'));
          localStorage.setItem('user', JSON.stringify(user.id));
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
          setSession(null);
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };
    initialize();
  }, []);

  const login = async (username, password) => {
    const response = await axios.post('https://api.scalexpert.com/users/login', {
      password,
      username,
    });
    if (response?.data?.user?.roleData?.role === 'admin' || 'moderator') {
      // eslint-disable-next-line camelcase
      const { user, access_token } = response.data;
      setSession(access_token);
      localStorage.setItem('user', JSON.stringify(user._id));
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    } else {
      throw new Error('Email or Password is incorrect');
    }
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    localStorage.removeItem('user');
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

// routes
// theme
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import Router from './routes';
import { userActions } from './redux/slices/allUsers';
import { storeActions } from './redux/slices/store';
import { dispatch } from './redux/store';
// import { leadActions } from './redux/slices/allLeads';

import useAuth from './hooks/useAuth';
import { challangeActions } from './redux/slices/allChallanges';

// ----------------------------------------------------------------------

export default function App() {
  const { user } = useAuth();
  // const { fetchUsers } = userActions;
  // const { fetchchallenges } = challangeActions;
  // const { fetchLeads } = leadActions;

  const { fetchStore } = storeActions;

  useEffect(() => {
    if (user !== null) {
      // dispatch(fetchUsers());
      // dispatch(fetchchallenges());
    }
    dispatch(fetchStore());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}

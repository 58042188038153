import PropTypes from 'prop-types';
import * as Yup from 'yup';
import merge from 'lodash/merge';
import { isBefore } from 'date-fns';
import { useSnackbar } from 'notistack';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import {
  Container,
  Tab,
  Button,
  IconButton,
  Tooltip,
  DialogActions,
  Tabs,
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  Typography,
  FormControlLabel,
  TextField,
  CircularProgress,
} from '@mui/material';

// redux
import { useDispatch } from '../../../redux/store';
import { createEvent, updateEvent, deleteEvent } from '../../../redux/slices/calendar';
// components
import Iconify from '../../../components/Iconify';
import { ColorSinglePicker } from '../../../components/color-utils';
import { FormProvider, RHFTextField, RHFSwitch } from '../../../components/hook-form';
// ----------------------------------------------------------------------

const COLOR_OPTIONS = [
  '#00AB55', // theme.palette.primary.main,
  '#1890FF', // theme.palette.info.main,
  '#54D62C', // theme.palette.success.main,
  '#FFC107', // theme.palette.warning.main,
  '#FF4842', // theme.palette.error.main
  '#04297A', // theme.palette.info.darker
  '#7A0C2E', // theme.palette.error.darker
];

const getInitialValues = (event, range) => {
  const _event = {
    title: '',
    description: '',
    textColor: '#1890FF',
    allDay: false,
    start: range ? new Date(range.start) : new Date(),
    end: range ? new Date(range.end) : new Date(),
  };

  if (event || range) {
    return merge({}, _event, event);
  }

  return _event;
};

// ----------------------------------------------------------------------

CalendarForm.propTypes = {
  selectedEvent: PropTypes.object,
  range: PropTypes.object,
  onCancel: PropTypes.func,
};

export default function CalendarForm({ selectedEvent, range, onCancel }) {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  // const isCreating = Object.keys(event).length === 0;

  const EventSchema = Yup.object().shape({
    title: Yup.string().max(255).required('Title is required'),
    description: Yup.string().max(5000),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    // defaultValues: getInitialValues(event, range),
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const isDateError = isBefore(new Date(values.end), new Date(values.start));

  return (
    <FormProvider methods={methods}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <RHFTextField
              name="leadname"
              value={selectedEvent?.lead_name}
              inputProps={{ readOnly: true, mt: 2 }}
              label="Lead Name"
            />
            <RHFTextField
              style={{ marginTop: '1rem' }}
              name="leadEmail"
              inputProps={{ readOnly: true, mt: 2 }}
              label="Lead Email"
              value={selectedEvent.lead_email}
            />
            <RHFTextField
              style={{ marginTop: '1rem' }}
              name="phoneNumber"
              inputProps={{ readOnly: true, mt: 2 }}
              label="Phone Number"
              value={selectedEvent.phone_number}
            />
            <RHFTextField
              style={{ marginTop: '1rem' }}
              name="rescheduled"
              inputProps={{ readOnly: true, mt: 2 }}
              label="Rescheduled"
              value={selectedEvent.rescheduled}
            />
            <RHFTextField
              style={{ marginTop: '1rem' }}
              name="accountType"
              inputProps={{ readOnly: true, mt: 2 }}
              label="Account Type"
              value={selectedEvent.account_type}
            />
          </Card>
        </Grid>
      </Stack>
    </FormProvider>
  );
}

// import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { HOST_URL } from '../../config';

// create slice
const name = 'challenge';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const challangeActions = { ...slice.actions, ...extraActions };
// export const accountsReducer = slice.reducer;
export default slice.reducer;

// implementation

function createInitialState() {
  return {
    challenge: { challenges: [], totalCount: 0 },
  };
}

function createExtraActions() {
  return {
    fetchchallenges: fetchchallenges(),
    fetchchallengeById: fetchchallengeById(),
    deleteChallenge: deleteChallenge(),
    createChallenge: createChallenge(),
    editChallenge: editChallenge(),
    // editAccounts: editAccounts(),
    // createAccount: createAccount(),
  };

  function fetchchallenges() {
    return createAsyncThunk(
      `${name}/fetchchallenges`,
      async ({ page, limit, title, status, sort, startDay, endDay }) => {
        // console.log(page , limit)
        try {
          const response = await axios.get(
            HOST_URL.concat(
              `challenge?page=${page}&limit=${limit}&title=${title}&status=${status}&sort=${sort}${
                startDay && `&startDay=${startDay}`
              }${endDay && `&endDay=${endDay}`}`
            )
          );
          //  console.log(response.data.challenge ,"res")
          return response.data.challenge;
        } catch (err) {
          // console.log( err.response ,"res")
          throw err.response;
        }
      }
    );
  }
  function fetchchallengeById() {
    return createAsyncThunk(`${name}/fetchchallenges`, async (challangeId, { rejectWithValue }) =>
      axios.get(`https://api.scalexpert.com/challenge/${challangeId}`)
    );
  }
  function deleteChallenge() {
    // https://Instasleb.convergix.io/Instasleb/admin/8/accounts
    return createAsyncThunk(`${name}/deleteChallenge`, async (challangeId, { rejectWithValue }) => {
      try {
        const response = await axios.delete(`https://api.scalexpert.com/challenge/${challangeId}`);
        return response;
      } catch (err) {
        if (!err.response) {
          throw err;
        }
        throw err.response.data;
      }
    });
  }
  function editChallenge() {
    return createAsyncThunk(`${name}/editChallenge`, async (obj, { rejectWithValue }) => {
      try {
        const response = await axios.patch(`https://api.scalexpert.com/challenge/${obj.id}`, obj);
        return response;
      } catch (err) {
        if (!err.response) {
          throw err;
        }
        throw err.response.data;
      }
    });
  }
  function createChallenge() {
    return createAsyncThunk(`${name}/createChallenge`, async (obj, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://api.scalexpert.com/challenge', obj);
        return response;
      } catch (err) {
        if (!err.response) {
          throw err;
        }
        throw err.response.data;
      }
    });
  }
}
function createExtraReducers() {
  return {
    ...fetchChallenges(),
    ...fetchchallengeById(),
    ...deleteChallenge(),
    ...editChallenge(),
    ...createChallenge(),
  };

  function fetchChallenges() {
    const { pending, fulfilled, rejected } = extraActions.fetchchallenges;
    return {
      [pending]: (state) => {
        state.challenge = { ...state.challenge, loading: true, challanges: [] };
      },
      [fulfilled]: (state, action) => {
        // console.log(action.payload ,'pay');

        state.challenge = {
          ...state.challenge,
          loading: false,
          challanges: action?.payload?.challenges,
          totalCount: action?.payload?.totalCount,
        };
      },
      [rejected]: (state, action) => {
        state.challenge = {
          ...state.challenge,
          loading: false,
          error: action.error,
          challanges: [],
          toast: { message: action?.error?.message, variant: 'error' },
        };
      },
    };
  }
  function fetchchallengeById() {
    // const { pending, fulfilled, rejected } = extraActions.fetchchallenges;
    // return {
    //   [pending]: (state) => {
    //     state.challenge = { loading: true, challanges: [] };
    //   },
    //   [fulfilled]: (state, action) => {
    //     state.challenge = {
    //       challanges: action?.payload?.data.challenge,
    //     };
    //   },
    //   [rejected]: (state, action) => {
    //     state.challenge = {
    //       error: action.error,
    //       challanges: [],
    //       toast: { message: action?.error?.message, variant: 'error' },
    //     };
    //   },
    // };
  }

  function deleteChallenge() {
    const { pending, fulfilled, rejected } = extraActions.deleteChallenge;
    return {
      [pending]: (state, action) => {
        state.challenge = {
          challanges:
            state?.challenge?.challanges?.filter((CHALLENGE) => !action?.meta?.arg.includes(CHALLENGE._id)) || [],
        };
      },
      [fulfilled]: (state, action) => {
        state.challenge = {
          challanges: state?.challenge?.challanges?.filter((CHALLENGE) => !action?.meta?.arg.includes(CHALLENGE._id)),
          toast: { message: 'Challenge deletion successfull', variant: 'success' },
        };
      },
      [rejected]: (state, action) => {
        state.challenge = {
          challanges: state?.challenge?.Challanges || [],
          toast: { message: action?.error?.message, variant: 'error' },
        };
      },
    };
  }

  function editChallenge() {
    const { pending, fulfilled, rejected } = extraActions.editChallenge;
    return {
      [pending]: (state) => {
        state.challenge = { loading: true, challanges: state?.challenge?.challanges || [] };
      },
      [fulfilled]: (state, action) => {
        // const agencyName = state?.accounts?.list?.find((data) => data.id === action.payload.data.agency_id);
        // action.payload.data.agency_name = agencyName.account_name;
        const updateIndex = state?.challenge.challanges.findIndex(
          (item) => item._id === action.payload.data.updatedChallenge.challenge._id
        );
        state.challenge = {
          loading: false,
          challanges: [
            ...state.challenge.challanges.slice(0, updateIndex),
            action.payload.data.updatedChallenge.challenge,
            ...state.challenge.challanges.slice(updateIndex + 1),
          ],
          toast: { message: 'Challenge edition successfull', variant: 'success' },
        };
      },
      [rejected]: (state, action) => {
        state.challenge = {
          loading: false,
          error: action.error,
          list: state?.challenge?.challanges || [],
          toast: { message: action?.error?.message, variant: 'error' },
        };
      },
    };
  }

  function createChallenge() {
    const { pending, fulfilled, rejected } = extraActions.createChallenge;

    return {
      [pending]: (state) => {
        state.challenge = { loading: true, challanges: state?.challenge?.challanges || [] };
      },
      [fulfilled]: (state, action) => {
        console.log(action.payload);
        state.challenge = {
          loading: false,
          challanges: [...state?.challenge?.challanges, action.payload.data.challengePage],
          toast: { message: 'Challenge creation successfull', variant: 'success' },
        };
      },
      [rejected]: (state, action) => {
        state.challenge = {
          loading: false,
          error: action.error,
          challanges: state?.challenge?.challanges || [],
          toast: { message: action?.error?.message, variant: 'error' },
        };
      },
    };
  }
}

// import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { url } from '../../config';

// create slice
const name = 'accounts';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

// export const accountsReducer = slice.reducer;
export default slice.reducer;

// implementation

function createInitialState() {
  return {
    // accounts: { agencies: [], list: [] },
  };
}

function createExtraActions() {
  return {
    // fetchAccounts: fetchAccounts(),
    // deleteAccounts: deleteAccounts(),
    // editAccounts: editAccounts(),
    // createAccount: createAccount(),
  };

  // function fetchAccounts() {
  //   return createAsyncThunk(`${name}/fetchAccounts`, async () =>
  //     axios.get(url.concat(`/user/${JSON.parse(localStorage.getItem('user')).id}/accounts`), {
  //       headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  //     })
  //   );
  // }

  // function deleteAccounts() {
  //   // https://Instasleb.convergix.io/Instasleb/admin/8/accounts
  //   return createAsyncThunk(`${name}/deleteAccounts`, async (accountIds, { rejectWithValue }) => {
  //     try {
  //       const response = await axios.delete(
  //         url.concat(`/admin/${JSON.parse(localStorage.getItem('user')).id}/accounts`),
  //         {
  //           headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  //           data: {
  //             account_ids: accountIds,
  //           },
  //         }
  //       );
  //       return response;
  //     } catch (err) {
  //       if (!err.response) {
  //         throw err;
  //       }
  //       throw err.response.data;
  //     }
  //   });
  // }
  // function editAccounts() {
  //   return createAsyncThunk(`${name}/editAccounts`, async (obj, { rejectWithValue }) => {
  //     try {
  //       const response = await axios.put(
  //         url.concat(`/user/${JSON.parse(localStorage.getItem('user')).id}/account`),
  //         obj,
  //         {
  //           headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
  //         }
  //       );
  //       return response;
  //     } catch (err) {
  //       if (!err.response) {
  //         throw err;
  //       }
  //       throw err.response.data;
  //     }
  //   });
  // }
  // function createAccount() {
  //   return createAsyncThunk(`${name}/createAccount`, async (obj, { rejectWithValue }) => {
  //     try {
  //       const response = await axios.post('https://api.scalexpert.com/challenge', obj);
  //       return response;
  //     } catch (err) {
  //       if (!err.response) {
  //         throw err;
  //       }
  //       throw err.response.data;
  //     }
  //   });
  // }
}
function createExtraReducers() {
  return {
    // ...fetchAccounts(),
    // ...deleteAccounts(),
    // ...editAccounts(),
    // ...createAccount(),
  };

  // function fetchAccounts() {
  //   const { pending, fulfilled, rejected } = extraActions.fetchAccounts;
  //   return {
  //     [pending]: (state) => {
  //       state.accounts = { loading: true, list: [] };
  //     },
  //     [fulfilled]: (state, action) => {
  //       state.accounts = {
  //         list: action.payload.data,
  //         agencies: action?.payload?.data.filter((account) => account.account_type === 'AGENCY'),
  //       };
  //     },
  //     [rejected]: (state, action) => {
  //       state.accounts = {
  //         error: action.error,
  //         list: [],
  //         toast: { message: action?.error?.message, variant: 'error' },
  //       };
  //     },
  //   };
  // }

  // function deleteAccounts() {
  //   const { pending, fulfilled, rejected } = extraActions.deleteAccounts;
  //   return {
  //     [pending]: (state, action) => {
  //       state.challenge = {
  //         challanges: state?.challenge?.list?.filter((challenge) => !action?.meta?.arg.includes(challenge._id)) || [],
  //       };
  //     },
  //     [fulfilled]: (state, action) => {
  //       state.challenge = {
  //         challanges: state?.challenge?.challanges?.filter((challenge) => !action?.meta?.arg.includes(challenge.id)),
  //         toast: { message: 'Account deletion successfull', variant: 'success' },
  //       };
  //     },
  //     [rejected]: (state, action) => {
  //       state.accounts = {
  //         challanges: state?.challenge?.challanges || [],
  //         toast: { message: action?.error?.message, variant: 'error' },
  //       };
  //     },
  //   };
  // }

  // function editAccounts() {
  //   const { pending, fulfilled, rejected } = extraActions.editAccounts;
  //   return {
  //     [pending]: (state) => {
  //       state.accounts = { loading: true, list: state?.accounts?.list || [] };
  //     },
  //     [fulfilled]: (state, action) => {
  //       // const agencyName = state?.accounts?.list?.find((data) => data.id === action.payload.data.agency_id);
  //       // action.payload.data.agency_name = agencyName.account_name;
  //       state.accounts = {
  //         list: state?.accounts.list.map((acc) => (acc.id === action.payload.data.id ? action.payload.data : acc)),
  //         toast: { message: 'Account edition successfull', variant: 'success' },
  //       };
  //     },
  //     [rejected]: (state, action) => {
  //       state.accounts = {
  //         error: action.error,
  //         list: state?.accounts?.list || [],
  //         toast: { message: action?.error?.message, variant: 'error' },
  //       };
  //     },
  //   };
  // }

  // function createAccount() {
  //   const { pending, fulfilled, rejected } = extraActions.createAccount;

  //   return {
  //     [pending]: (state) => {
  //       state.accounts = { loading: true, list: state?.accounts?.list || [] };
  //     },
  //     [fulfilled]: (state, action) => {
  //       const agencyName = state?.accounts?.list?.find((data) => data.id === action.payload.data.agency_id);
  //       action.payload.data.agency_name = agencyName.account_name;
  //       state.accounts = {
  //         list: [action.payload.data, ...state?.accounts?.list],
  //         toast: { message: 'Account creation successfull', variant: 'success' },
  //       };
  //     },
  //     [rejected]: (state, action) => {
  //       state.accounts = {
  //         error: action.error,
  //         list: state?.accounts?.list || [],
  //         toast: { message: action?.error?.message, variant: 'error' },
  //       };
  //     },
  //   };
  // }
}

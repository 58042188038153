import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// import { HOST_URL } from '../../config';
import { url } from '../../config';

// const initialState = [];

const name = 'user';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const slice = createSlice({
  name,
  initialState,
  extraReducers,

});

export const userActions = { ...slice.actions, ...extraActions };

export default slice.reducer;

function createInitialState() {
  return {
    user: { allUsers: [] ,totalLength:0 ,AvgLifeTime:0 ,  loading:false , error:false , analyticsData:{} , heartsData: {}},
    selectedUser: {},
  };
}

function createExtraActions() {
  return {
    fetchUsers: fetchUsers(),
    deleteUsers: deleteUsers(),
    updateUser: updateUser(),
    createUser: createUser(),
    fetchAnalytics:fetchAnalytics() ,
    fetchHearts : fetchHearts()
    // fetchUserSearch:fetchUserSearch()
  };
}

function fetchUsers() {
  return createAsyncThunk(`${name}/fetchUsers`, async ({page,limit,search,status,time,label,fromAge,toAge}) => axios.get(`https://api.scalexpert.com/users/all?page=${page}&limit=${limit}&search=${search}&status=${status}&time=${time}&label=${label}&fromAge=${fromAge}&toAge=${toAge}`));
}


function fetchAnalytics() {
  return createAsyncThunk(`${name}/fetchAnalytics`, async () => axios.get(`https://api.scalexpert.com/admin/config/users`));
}

function fetchHearts() {
  return createAsyncThunk(`${name}/fetchHearts`, async () => axios.get(`https://api.scalexpert.com/hearts/config`));
}

  
// function fetchAnalytics() {
//   return createAsyncThunk(`${name}/fetchAnalytics`, async () => axios.get(`http://localhost:4000/admin/config/users`));
// }

// function fetchHearts() {
//   return createAsyncThunk(`${name}/fetchHearts`, async () => axios.get(`http://localhost:4000/hearts/config`));
// }





// function fetchUsersById() {
//   return createAsyncThunk(`${name}/fetchUsersById`, async (userId) => axios.get(`https://api.scalexpert.com/users/${userId}`));
// }

function deleteUsers() {
  return createAsyncThunk(`${name}/deleteUsers`, async (userIds) => {
    try {
      const response = await axios.delete(`https://api.scalexpert.com/users/${userIds}`);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      throw err.response.data;
    }
  });
}

function updateUser() {
  return createAsyncThunk(`${name}/updateUser`, async (obj) => {
    console.log(obj ,"obj-----")
    try {
      const response = await axios.patch(`https://api.scalexpert.com/users/${obj.userId}`,
        obj.data
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      throw err.response.data;
    }
  });
}

function createUser() {
  return createAsyncThunk(`${name}/createUser`, async (obj) => {
    try {
      const response = await axios.post(
        url.concat(`/admin/${JSON.parse(localStorage.getItem('user')).id}/create/account`),
        obj,
        { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      );
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      throw err.response.data;
    }
  });
}

function createExtraReducers() {
  return {
    ...fetchUsers(),
    // ...fetchUsersById(),
    ...deleteUsers(),
    ...updateUser(),
    ...createUser(),
    ...fetchAnalytics(),
    ...fetchHearts()
  };

  function fetchUsers() {
    const { pending, fulfilled, rejected } = extraActions.fetchUsers;
    return {
      [pending]: (state) => {
        state.user = {...state.user , loading: true, allUsers: [] };
      },
      [fulfilled]: (state, action) => {
        
        // console.log(action.payload?.data?.users?.users ,"payload")
        state.user = {...state.user ,loading: false , allUsers: action.payload?.data?.users?.users || []  ,totalLength:action.payload?.data?.users?.totalCount , AvgLifeTime:action.payload?.data?.users?.averageAgeOfInactiveUsers  };
      },
      [rejected]: (state, action) => {
        state.user = {
          error: true,
          allUsers: [],
          toast: {...state.user , message: action?.error?.message, variant: 'error' },
          loading: false ,
        };
      },
    };
  }

  function fetchAnalytics() {
    const { pending, fulfilled, rejected } = extraActions.fetchAnalytics;
    return {
      [pending]: (state) => {
        state.user = {...state.user ,  loading: true, allUsers: [...state.user.allUsers] };
      },
      [fulfilled]: (state, action) => {
        console.log(action.payload ,"USR -payload--")
        state.user = {...state.user , allUsers: [...state.user.allUsers] ,loading:false , analyticsData:action.payload?.data?.analyticsData , heartsData: {...state.user.heartsData} };
      },
      [rejected]: (state) => {
        state.user = {...state.user ,
          error: true,
          allUsers: [...state.user.allUsers],
          loading:false ,
        };
      },
    };
  }

  function fetchHearts() {
    const { pending, fulfilled, rejected } = extraActions.fetchHearts;
    return {
      [pending]: (state) => {
        state.user = {...state.user ,  loading: true, allUsers: [...state.user.allUsers] };
      },
      [fulfilled]: (state, action) => {
        console.log(action.payload ,"HEART - payload--")
        state.user = {...state.user , allUsers: [...state.user.allUsers] ,loading:false , analyticsData: {...state.user.analyticsData} , heartsData:action.payload?.data  };
      },
      [rejected]: (state) => {
        state.user = {...state.user ,
          error: true,
          allUsers: [...state.user.allUsers],
          loading:false ,
        };
      },
    };
  }
  


  function deleteUsers() {
    const { pending, fulfilled, rejected } = extraActions.deleteUsers;
    return {
      [pending]: (state, action) => {
        state.user = {
          loading: true,
          allUsers: state?.user?.allUsers?.filter((user) => !action?.meta?.arg.includes(user._id)) || [],
        };
      },
      [fulfilled]: (state) => {
        state.user = {
          allUsers: state?.user?.allUsers,
          toast: { message: 'Account deletion successfull', variant: 'success' },
        };
      },
      [rejected]: (state, action) => {
        state.user = {
          error: action.error,
          allUsers: state?.user?.allUsers || [],
          toast: { message: action?.error?.message, variant: 'error' },
        };
      },
    };
  }

  function updateUser() {
    const { pending, fulfilled, rejected } = extraActions.updateUser;
    return {
      [pending]: (state) => {
        state.user = { loading: true, allUsers: state?.user?.allUsers || [] ,analyticsData: {...state.user.analyticsData} , heartsData: {...state.user.heartsData} };
      },
      [fulfilled]: (state, action) => {
        state.user = {
          loadingg: false,
          allUsers: state?.user?.allUsers.map((user) =>
            user._id === action.payload.data.user.user._id ? action.payload.data.user.user : user
          ),
          analyticsData: {...state.user.analyticsData} ,
          heartsData: {...state.user.heartsData} ,
          toast: { message: 'User edition successfull', variant: 'success' },
        };
      },
      [rejected]: (state, action) => {
        state.user = {
          loadingg: false,
          error: action.error,
          analyticsData: {...state.user.analyticsData},
          heartsData: {...state.user.heartsData} ,
          allUsers: state?.user?.allUsers || [],
          toast: { message: action?.error?.message, variant: 'error' },
        };
      },
    };
  }
  function createUser() {
    const { pending, fulfilled, rejected } = extraActions.createUser;
    return {
      [pending]: (state) => {
        state.user = { allUsers: state?.user?.allUsers || [] };
      },
      [fulfilled]: (state, action) => {
        state.user = {
          allUsers: [action.payload.data, ...state?.user?.allUsers],
          toast: { message: 'User creation successfull', variant: 'success' },
        };
      },
      [rejected]: (state, action) => {
        state.user = {
          error: action.error,
          allUsers: state?.user?.allUsers || [],
          toast: { message: action?.error?.message, variant: 'error' },
        };
      },
    };
  }
}

// Reducer

// Actions
